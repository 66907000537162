import React from 'react';
import PropTypes from 'prop-types';

import Weather from '../weather/Weather';
import EnergyStatus from '../energyStatus/EnergyStatus';
import WeekOneCharts from './WeekOneCharts';
import WeekTwoCharts from './WeekTwoCharts';

const Week = ({ week, mixData }) => {
  return (
    <>
      <div className="my-2">
        <Weather week={week} />
      </div>
      <p className="mb-2 text-sm font-bold text-center">Demand vs output</p>
      { week === 'one' && (
        <WeekOneCharts mix={mixData.mix} />
      )}
      { week === 'two' && (
        <WeekTwoCharts mix={mixData.mix} />
      )}
      <div className="h-32 flex flex-col justify-center energy-status">
        <EnergyStatus energyExcess={mixData.energy.excess} energyMissing={mixData.energy.missing} />
      </div>
    </>
  );
};

Week.propTypes = {
  week: PropTypes.oneOf(['one', 'two']),
  mixData: PropTypes.object
};

export default Week;
