const lineOptions = (capacity) => ({
  'maintainAspectRatio': false,
  'annotation': {
    'annotations': [
      {
        'drawTime': 'afterDraw',
        'type': 'line',
        'mode': 'horizontal',
        'scaleID': 'y-axis-0',
        'value': capacity,
        'borderColor': '#17242A',
        'borderWidth': 1,
        'borderDash': [2, 2]
      }
    ]
  },
  'legend': {
    'display': false
  },
  'scales': {
    'xAxes': [
      {
        'display': true,
        'gridLines': {
          'display': false
        },
        'ticks': {
          'display': false
        },
        'type': 'time'
      }
    ],
    'yAxes': [
      {
        'display': true,
        'gridLines': {
          'display': false
        },
        'ticks': {
          'min': 0,
          'max': 80,
          'stepSize': 20,
          'fontSize': 10,
          'fontColor': '#17242a',
          'fontFamily': "'Inter', sans-serif"
        }
      }
    ]
  },
  tooltips: {
    enabled: false,
    mode: 'index',
    intersect: false,
  }
});

export default lineOptions;
