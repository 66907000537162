import React from 'react';
import PropTypes from 'prop-types';
import { X } from 'react-feather';

import './Modal.css';

const Modal = ({ className, display, setDisplay, neonGreen, children, dataTestId }) => {
  const visibility = display ? 'modal-show' : '';
  const bg = neonGreen ? 'bg-neon-green-500' : 'bg-white';
  return (
    <div className={'modal ' + visibility + ' ' + className}
      role="dialog" data-test-id={dataTestId}>
      <div className={bg + ' md:my-8 max-w-5xl mx-auto p-8 md:p-12 overflow-y-auto'} tabIndex="0">
        <div className="btn md:pl-8 md:pr-8 cursor-pointer btn-close" onClick={() => setDisplay(false)}>
          <X className="inline mr-2" />
          <span>Close</span>
        </div>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  display: PropTypes.bool,
  setDisplay: PropTypes.func,
  neonGreen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ]),
  dataTestId: PropTypes.string
};

export default Modal;
