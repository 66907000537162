import DEMAND from '../demand.json';
import LABELS from './labels.json';

export const getSolar = (capacity) => (
  {
    'label': 'Solar Energy Produced',
    'fill': true,
    'lineTension': 0.1,
    'backgroundColor': 'rgba(255, 120, 79, 1)',
    'borderColor': 'rgba(255, 120, 79, 1)',
    'borderWidth': 0,
    'borderCapStyle': 'butt',
    'borderDash': [],
    'borderDashOffset': 0.0,
    'borderJoinStyle': 'miter',
    'pointBorderColor': 'rgba(255, 120, 79, 1)',
    'pointBackgroundColor': 'rgba(255, 120, 79, 1)',
    'pointBorderWidth': 1,
    'pointHoverRadius': 0,
    'pointHoverBackgroundColor': 'rgba(255, 120, 79, 1)',
    'pointHoverBorderColor': 'rgba(255, 120, 79, 1)',
    'pointHoverBorderWidth': 1,
    'pointRadius': 0,
    'pointHitRadius': 1,
    'data': [0.00, 0.00, 0.00, 0.00, 0.08, 0.29, 0.33, 0.15, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.13, 0.37, 0.40, 0.19, 0.01, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.10, 0.38, 0.44, 0.20, 0.01, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.20, 0.65, 0.74, 0.37, 0.01, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.14, 0.40, 0.40, 0.19, 0.01, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.14, 0.42, 0.45, 0.21, 0.01, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.09, 0.32, 0.37, 0.19, 0.01, 0.00, 0.00, 0.00].map(x => x * capacity)
  }
);

const solar = (capacity) => ({
  'labels': LABELS,
  'datasets': [
    DEMAND,
    getSolar(capacity)
  ]
});

export default solar;
