import React  from 'react';
import PropTypes from 'prop-types';
import { Plus, Minus, Info } from 'react-feather';

import './EnergySource.css';

const EnergySource = ({ sourceName, mixTotal, gigawatts, setGigawatts, maximumGigawatts, children }) => {
  const updateGigawatts = (value) => {
    if (value >= maximumGigawatts) {
      setGigawatts(maximumGigawatts);
    } else if (mixTotal + value - gigawatts < 0 || value < 0) {
      setGigawatts(0);
    } else {
      setGigawatts(value);
    }
  };

  return (
    <div className={`flex flex-row bg-white shadow-md md:shadow-lg rounded-sm py-2 md:py-6 mb-2 md:mb-4 source-${sourceName}`}
      data-test-id={`source-${sourceName}`}>
      <div className="w-12 h-12 md:w-20 md:h-20 ml-2 mr-2">
        {children}
      </div>
      <div className="flex flex-row flex-grow items-center justify-between mr-4 md:items-start md:flex-col md:justify-center">
        <Info className="hidden md:block -mt-4 -mr-2 m-auto" aria-label={`Information on ${sourceName}`} data-tip data-for={`info-${sourceName}`} />
        <span className="font-semibold">{sourceName}</span>
        <span className="font-black text-4xl leading-none">{gigawatts} <span className="font-medium text-sm">GW</span></span>
      </div>
      <Info className="block md:hidden -mt-2" size="20" aria-label={`Information on ${sourceName}`} data-tip data-for={`info-mobile-${sourceName}`} />
      <div className="flex flex-row items-center md:flex-col md:justify-between md:-mr-4">
        <div className={`bg-midnight-blue rounded-sm p-1 mr-2 md:mr-0 btn-minus ${ gigawatts <= 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
          data-test-id="mix-value"
          onClick={() => updateGigawatts(gigawatts - 2)}>
          <Minus color="#65fef0" />
        </div>
        <div className={`bg-midnight-blue rounded-sm p-1 mr-2 md:mr-0 md:order-first btn-plus ${ gigawatts >= maximumGigawatts ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={() => updateGigawatts(gigawatts + 2)}>
          <Plus color="#65fef0" />
        </div>
      </div>
    </div>
  );
};

EnergySource.propTypes = {
  sourceName: PropTypes.string,
  mixTotal: PropTypes.number,
  gigawatts: PropTypes.number,
  setGigawatts: PropTypes.func,
  maximumGigawatts: PropTypes.number,
  children: PropTypes.element
};

export default EnergySource;
