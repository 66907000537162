import DEMAND from '../demand.json';
import LABELS from './labels.json';

export const getWind = (capacity) => {
  return ({
    'label': 'Wind Energy Produced',
    'fill': true,
    'lineTension': 0.1,
    'backgroundColor': 'rgba(89, 192, 99, 1)',
    'borderColor': 'rgba(89, 192, 99, 1)',
    'borderWidth': 0,
    'borderCapStyle': 'butt',
    'borderDash': [],
    'borderDashOffset': 0.0,
    'borderJoinStyle': 'miter',
    'pointBorderColor': 'rgba(89, 192, 99, 1)',
    'pointBackgroundColor': 'rgba(89, 192, 99, 1)',
    'pointBorderWidth': 1,
    'pointHoverRadius': 0,
    'pointHoverBackgroundColor': 'rgba(89, 192, 99, 1)',
    'pointHoverBorderColor': 'rgba(89, 192, 99, 1)',
    'pointHoverBorderWidth': 1,
    'pointRadius': 0,
    'pointHitRadius': 1,
    'data': [0.16, 0.13, 0.09, 0.08, 0.07, 0.06, 0.07, 0.08, 0.12, 0.11, 0.09, 0.09, 0.09, 0.09, 0.11, 0.13, 0.20, 0.30, 0.37, 0.43, 0.47, 0.49, 0.47, 0.42, 0.38, 0.34, 0.30, 0.24, 0.14, 0.08, 0.05, 0.06, 0.08, 0.09, 0.13, 0.12, 0.10, 0.10, 0.11, 0.12, 0.15, 0.18, 0.21, 0.21, 0.23, 0.22, 0.26, 0.26, 0.25, 0.24, 0.26, 0.28, 0.28, 0.25, 0.23, 0.21, 0.21, 0.22, 0.21, 0.18, 0.15, 0.13, 0.14, 0.15, 0.15, 0.13, 0.15, 0.16, 0.18, 0.19, 0.18, 0.19, 0.22, 0.27, 0.30, 0.32, 0.33, 0.39, 0.41, 0.44, 0.43, 0.44, 0.43, 0.44].map(x => x * capacity)
  });
};

const wind = (capacity) => ({
  'labels': LABELS,
  'datasets': [
    DEMAND,
    getWind(capacity)
  ]
});

export default wind;
