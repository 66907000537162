import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';

import Introduction from './components/pages/Introduction';
import KeepTheLightsOn from './components/pages/KeepTheLightsOn';
import Disclaimer from './components/pages/Disclaimer';
import EmissionsChart from './components/pages/EmissionsChart';
import Faqs from './components/pages/Faqs';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import NotFound from './components/pages/NotFound';
import Tour from './components/pages/Tour';

import './App.css';

const App = () => {
  return (
    <Router>
      <>
        <nav className="absolute bottom-0 left-0 w-full text-center hidden">
          <ul className="py-4">
            <li className="inline-block px-4">
              <Link to="/keep-the-lights-on">Home</Link>
            </li>
            <li className="inline-block px-4">
              <Link to="/privacy/">Privacy Policy</Link>
            </li>
            <li className="inline-block px-4">
              <Link to="/disclaimer/">Disclaimer</Link>
            </li>
          </ul>
        </nav>

        <Switch>
          <Route path="/" exact component={Introduction} />
          <Route path="/disclaimer/" component={Disclaimer} />
          <Route path="/emissions-chart/" component={EmissionsChart} />
          <Route path="/faqs/" component={Faqs} />
          <Route path="/keep-the-lights-on/" component={KeepTheLightsOn} />
          <Route path="/privacy-policy/" component={PrivacyPolicy} />
          <Route path="/tour/" component={Tour} />
          <Route component={NotFound} />
        </Switch>
      </>
    </Router>
  );
};

export default App;
