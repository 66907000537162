import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { Info, Menu } from 'react-feather';

import EnergySource from '../energySources/EnergySource';
import WindTurbine from '../energySources/WindTurbine';
import SolarPanel from '../energySources/SolarPanel';
import NuclearAtom from '../energySources/NuclearAtom';
import Gas from '../energySources/Gas';
import EnergyStorage from '../storage/EnergyStorage';
import MenuBar from '../menu/MenuBar';
import EnergySourceTooltips from './EnergySourceTooltips';

import { averageDemand } from '../../utils/utils';

const maximumWindCapacity = 180;
const maximumSolarCapacity = 400;
const maximumNuclearCapacity = 36;
const maximumGasCapacity = 60;

const EnergySourceControls = ({ wind, setWind, solar, setSolar, nuclear, setNuclear, gas, setGas, storage, setStorage }) => {

  const [mixTotal, setMixTotal] = useState(0);
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    setMixTotal(gas + nuclear + wind + solar);
  }, [gas, nuclear, wind, solar]);

  return (
    <>
      <div className="hidden md:flex h-12 flex-row my-2" data-test-id="menu-btn" onClick={() => setMenuVisible(true)}>
        <Menu />
        <p className="uppercase font-bold ml-4">Menu</p>
      </div>
      <p className="text-sm mb-2"><strong>Power generation</strong> (capacity)</p>
      <div>
        <EnergySource sourceName="Wind" mixTotal={mixTotal} gigawatts={wind} setGigawatts={setWind} maximumGigawatts={maximumWindCapacity}>
          <WindTurbine animate={wind > 0} />
        </EnergySource>
        <EnergySource sourceName="Solar" mixTotal={mixTotal} gigawatts={solar} setGigawatts={setSolar} maximumGigawatts={maximumSolarCapacity}>
          <SolarPanel animate={solar > 0} />
        </EnergySource>
        <EnergySource sourceName="Nuclear" mixTotal={mixTotal} gigawatts={nuclear} setGigawatts={setNuclear} maximumGigawatts={maximumNuclearCapacity}>
          <NuclearAtom animate={nuclear > 0} />
        </EnergySource>
        <EnergySource sourceName="Gas" mixTotal={mixTotal} gigawatts={gas} setGigawatts={setGas} maximumGigawatts={maximumGasCapacity}>
          <Gas animate={gas > 0} />
        </EnergySource>
      </div>
      <div className="flex md:h-32 my-4 items-center">
        <p>
          Your power capacity is <span className="font-bold">{wind + solar + nuclear + gas}<span className="text-sm">GW</span></span>.
          In 2020, average UK power demand is approximately {Math.round(averageDemand)} <span className="text-sm">GW</span>, which could double by 2050.
          <Link to={`/faqs?wind=${wind}&solar=${solar}&nuclear=${nuclear}&storage=${storage}&gas=${gas}`}
            className="font-bold">
            &nbsp;See here.
          </Link>
        </p>
        <div data-tip data-for="capacity">
          <Info aria-label="Information on capacity" />
        </div>
        <ReactTooltip class="w-64 opaque" id="capacity" type="light" effect="solid" place="right" border={true}
          borderColor="black" multiline={true}>
            Did you know, the UK consumes on average 800 GWh of electricity a day, which is enough to power more than
            3 million washing machines for a year
        </ReactTooltip>
      </div>
      <EnergyStorage gigawattHours={storage} setGigawattHours={setStorage} />
      <MenuBar visible={menuVisible} setVisible={setMenuVisible} wind={wind} solar={solar} nuclear={nuclear} gas={gas} storage={storage} />
      <EnergySourceTooltips />
    </>
  );
};

EnergySourceControls.propTypes = {
  wind: PropTypes.number,
  setWind: PropTypes.func,
  solar: PropTypes.number,
  setSolar: PropTypes.func,
  nuclear: PropTypes.number,
  setNuclear: PropTypes.func,
  gas: PropTypes.number,
  setGas: PropTypes.func,
  storage: PropTypes.number,
  setStorage: PropTypes.func
};

export default EnergySourceControls;
