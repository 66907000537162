import React from 'react';
import PropTypes from 'prop-types';

const setMix = (setWind, setSolar, setNuclear, setGas, setStorage) => {
  setWind(0);
  setSolar(0);
  setNuclear(0);
  setGas(0);
  setStorage(0);
};

const ResetButton = ({ setWind, setSolar, setNuclear, setGas, setStorage }) => {
  return (
    <div className="m-2 bg-midnight-blue md:bg-neon-green-500 md:text-midnight-blue md:w-1/2 md:text-center md:m-auto
    rounded-sm no-underline text-white font-bold py-2 px-4 rounded-sm"
    data-test-id="reset-mix-button"
    onClick={() => setMix(setWind, setSolar, setNuclear, setGas, setStorage)}>
            Reset mix
    </div>
  );
};

ResetButton.propTypes = {
  setWind: PropTypes.func,
  setSolar: PropTypes.func,
  setNuclear: PropTypes.func,
  setGas: PropTypes.func,
  setStorage: PropTypes.func
};

export default ResetButton;
