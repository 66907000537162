import React from 'react';
import PropTypes from 'prop-types';

const SummarySource = ({ sourceName, gigawatts, units, children }) => {
  return (
    <div className="flex flex-row md:flex-col bg-white shadow-md md:shadow-lg rounded-sm p-4 mb-2 md:mb-4">
      <div className="w-12 h-12 md:w-20 md:h-20 ml-2 mr-2">
        {children}
      </div>
      <div className="flex flex-row flex-grow items-center justify-between md:flex-col md:justify-center">
        <span className="font-semibold">{sourceName}</span>
        <span className="font-black text-3xl md:text-4xl leading-none">{gigawatts} <span className="font-medium text-sm">{units}</span></span>
      </div>
    </div>
  );
};

SummarySource.propTypes = {
  sourceName: PropTypes.string,
  gigawatts: PropTypes.number,
  units: PropTypes.string,
  children: PropTypes.element
};

export default SummarySource;
