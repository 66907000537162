import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

import lineOptions from '../../data/lineOptions.js';
import stackedOptions from '../../data/stackedOptions.js';
import wind from '../../data/weekOne/wind.js';
import solar from '../../data/weekOne/solar.js';
import nuclear from '../../data/weekOne/nuclear.js';
import gas from '../../data/weekOne/gas.js';
import stack from '../../data/weekOne/stack.js';

import { sumValues } from '../../utils/utils';

import LABELS from '../../data/weekOne/labels';
import DEMAND from '../../data/demand';

const WeekOneCharts = ({ mix }) => {
  return (
    <>
      <div className="hidden md:block relative h-32 w-full mb-4">
        <Line data={wind(mix.wind)} options={lineOptions(mix.wind)} height={128} />
      </div>
      <div className="hidden md:block relative h-32 w-full mb-4">
        <Line data={solar(mix.solar)} options={lineOptions(mix.solar)} height={128} />
      </div>
      <div className="hidden md:block relative h-32 w-full mb-4">
        <Line data={nuclear(mix.nuclear)} options={lineOptions(mix.nuclear)} height={128} />
      </div>
      <div className="hidden md:block relative h-32 w-full mb-4">
        <Line data={gas(mix.gas, mix.wind, mix.solar, mix.nuclear, mix.storage)} options={lineOptions(mix.gas)} height={128} />
      </div>
      <div className="relative h-32 w-full mb-4 mt-8">
        <div className="absolute h-32 w-full mb-4 mt-8">
          <Line data={stack(mix.gas, mix.wind, mix.solar, mix.nuclear, mix.storage)} options={stackedOptions(sumValues(mix))} height={128} />
        </div>
        <div className="absolute h-32 w-full mb-4 mt-8">
          <Line data={{ 'labels': LABELS, 'datasets': [DEMAND] }} options={lineOptions(0, 5)} height={128} />
        </div>
      </div>
    </>
  );
};

WeekOneCharts.propTypes = {
  mix: PropTypes.object
};

export default WeekOneCharts;
