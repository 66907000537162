import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronRight } from 'react-feather';

import './Accordion.css';

const Accordion = ({ title, content }) => {
  const ref = useRef(null);
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('accordion--icon');

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'accordion--active' : '');
    setHeightState(
      setActive === 'accordion--active' ? '0px' : `${ref.current.scrollHeight}px`
    );
    setRotateState(
      setActive === 'accordion--active' ? 'accordion--icon' : 'accordion--icon accordion--icon--rotate'
    );
  }
  
  return (
    <div className="accordion--section">
      <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p className="accordion--title">{title}</p>
        <ChevronRight className={setRotate} />
      </button>
      <div ref={ref} style={{ maxHeight: `${setHeight}` }} className="accordion--content">
        <div
          className="accordion--text"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
};

export default Accordion;
