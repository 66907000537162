import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'react-feather';

import LightMap from '../lightMap/LightMap';

const Introduction = () => {
  return (
    <main className="min-h-screen flex intro-background">
      <div className="container mx-auto self-center">
        <div className="flex flex-col lg:flex-row">
          <div className="mx-4 md:mx-20 mb-8 pt-4 self-center lg:w-4/5 xl:w-3/5">
            <h1 className="mb-6 max-w-sm" data-test-id="intro-title">
              <svg viewBox="0 0 343 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <text fill="#F7FF46" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize="54" fontWeight="900" letterSpacing="0.025em">
                  <tspan x="-1" y="36.4545">Net Zero</tspan>
                </text>
                <text fill="#F7FF46" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize="28" fontWeight="900" letterSpacing="-0.025em">
                  <tspan x="-1" y="72.4545">Energy Challenge</tspan>
                </text>
              </svg>
            </h1>
            <p className="mb-6 text-lg text-white font-bold" data-test-id="intro-subtitle">
              Can you overcome low levels of wind and sun to create an affordable energy mix?
            </p>
            <p className="mb-6 text-white" data-test-id="intro-blurb">
              Create an energy mix that keeps Great Britain powered, reaches Net Zero and is affordable, using
              currently available, scalable technologies.<br />
              The weather data used in this game includes a ‘low wind, low sun’ week which illustrates some of the
              challenges faced by the national grid recently.
            </p>
            <Link to="/tour" className="inline-flex justify-between text-neon-green-500 px-4 py-2 underline cursor-pointer" data-test-id="tour-nav">How the game works</Link>
            <Link to="/keep-the-lights-on" className="inline-flex justify-between btn-secondary no-underline text-neon-green font-bold py-2 px-4 rounded-sm" data-test-id="game-nav">Start<ArrowRight className="inline ml-8" /></Link>
          </div>
          <div className="w-full p-4 md:p-0 self-center">
            <LightMap percentage={30} />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Introduction;
