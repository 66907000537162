import React from 'react';
import PropTypes from 'prop-types';
import { allowedCarbonEmissions, highCost, mediumCost } from '../../utils/utils';

const SummaryOverview = ({ score, weekOneSumCost, averageCarbon }) => {
  return (
    <div className="flex justify-around">
      <div className="content-center text-center">
        <h2 className="font-bold text-xl md:text-4xl">
          Score
        </h2>
        <p className="font-black uppercase text-2xl md:text-5xl" data-test-id="summary-score">
          {score}%
        </p>
      </div>
      <div className="content-center text-center">
        <h2 className="font-bold text-xl md:text-4xl">
          Cost
        </h2>
        <p className="font-black uppercase text-2xl md:text-5xl">
          {weekOneSumCost > highCost ? ' High ' : weekOneSumCost > mediumCost ? ' Medium ' : ' Low '}
        </p>
      </div>
      <div className="content-center text-center">
        <h2 className="font-bold text-xl md:text-4xl">
          Net Zero?
        </h2>
        <p className="font-black uppercase text-2xl md:text-5xl">
          {averageCarbon >= allowedCarbonEmissions ? 'No' : 'Yes'}
        </p>
      </div>
    </div>
  );
};

SummaryOverview.propTypes = {
  score: PropTypes.number,
  weekOneSumCost: PropTypes.number,
  averageCarbon: PropTypes.number
};

export default SummaryOverview;
