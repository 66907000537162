import React, { useState } from 'react';
import queryString from 'query-string';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { Link } from 'react-router-dom';

import PageOne from '../tour/PageOne';
import PageTwo from '../tour/PageTwo';
import PageThree from '../tour/PageThree';

const Pages = [<PageOne key='page-1' />, <PageTwo key='page-2' />, <PageThree key='page-3' />];

const Tour = () => {

  const [page, setPage] = useState(0);
  const wind = queryString.parse(window.location.search).wind ? queryString.parse(window.location.search).wind : 0;
  const solar = queryString.parse(window.location.search).solar ? queryString.parse(window.location.search).solar : 0;
  const nuclear = queryString.parse(window.location.search).nuclear ? queryString.parse(window.location.search).nuclear : 0;
  const storage = queryString.parse(window.location.search).storage ? queryString.parse(window.location.search).storage : 0;
  const gas = queryString.parse(window.location.search).gas ? queryString.parse(window.location.search).gas : 0;

  return (
    <main className="min-h-screen flex intro-background">
      <div className="container mx-auto self-center">
        <h1 className="mb-6 max-w-sm p-4">
          <svg viewBox="0 0 343 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <text fill="#F7FF46" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize="54" fontWeight="900" letterSpacing="0.025em">
              <tspan x="-1" y="36.4545">Net Zero</tspan>
            </text>
            <text fill="#F7FF46" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} fontFamily="Inter" fontSize="28" fontWeight="900" letterSpacing="-0.025em">
              <tspan x="-1" y="72.4545">Energy Challenge</tspan>
            </text>
          </svg>
        </h1>
        <ol className="list-decimal list-inside inline-flex text-white">
          <li className={`m-4${page === 0 ? ' text-neon-green-500' : ''}`}>Energy Sources</li>
          <li className={`m-4${page === 1 ? ' text-neon-green-500' : ''}`}>Energy Demand</li>
          <li className={`m-4${page === 2 ? ' text-neon-green-500' : ''}`}>Energy Storage</li>
        </ol>
        <Link
          to={`/keep-the-lights-on?wind=${wind}&solar=${solar}&nuclear=${nuclear}&storage=${storage}&gas=${gas}`}
          className="float-right m-2 btn-secondary no-underline text-neon-green-500 font-bold py-2 px-4 rounded-sm"
          data-test-id="game-nav">
          Start your mix
          <ArrowRight className="inline ml-8" />
        </Link>
        <div className="md:flex justify-between block bg-white md:my-8 mx-auto p-12">
          {Pages[page]}
        </div>
        <div className="flex flex-row-reverse">
          <div
            className={`flex justify-between md:inline-flex btn no-underline px-4 py-2 underline cursor-pointer${ page >= 2 ? ' opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => page < 2 ? setPage(page + 1) : setPage(2)} data-test-id="tour-next">
            Next
            <ArrowRight />
          </div>
          <div
            className={`tour-prev flex justify-between md:inline-flex btn no-underline px-4 py-2 underline cursor-pointer${ page <= 0 ? ' opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => page > 0 ? setPage(page - 1) : setPage(0)} data-test-id="tour-prev">
            Previous
            <ArrowLeft />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Tour;
