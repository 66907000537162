import React, { useState } from 'react';
import queryString from 'query-string';

import MenuBar from '../menu/MenuBar';

import { Menu } from 'react-feather';

import './PrivacyPolicy.css';

const PrivacyPolicy = () => {

  const [menuVisible, setMenuVisible] = useState(false);
  const parsed = queryString.parse(window.location.search);

  return (
    <div className="privacy-body md:m-auto mx-4 pt-8 ml-4 mr-4" data-test-id="privacy-policy-container">
      <div className="h-12 flex flex-row my-2" data-test-id="menu-btn" onClick={() => setMenuVisible(true)}>
        <Menu />
        <p className="uppercase font-bold ml-4">Menu</p>
      </div>
      <h1 className="font-bold text-3xl">Privacy Policy</h1>
      <p className="p2">
        To get very basic information about the behaviour of our visitors, we use Simple Analytics. This analytics
        software gives us insight about our visitors only in general, but not about individuals by itself, as it does
        not track visitors and does not store any personal identifiable information. Go to their documentation to find
        out what Simple Analytics collects (and most importantly what they don’t).
      </p>
      <p className="p2">
        Read more: 
        <a className="font-bold" title="Go to https://docs.simpleanalytics.com/your-privacy-policy (Opens in a new window)" href="https://docs.simpleanalytics.com/your-privacy-policy" rel="noopener noreferrer" target="_blank"> https://docs.simpleanalytics.com/your-privacy-policy</a>
      </p>
      <MenuBar visible={menuVisible} setVisible={setMenuVisible} wind={parsed.wind} solar={parsed.solar} nuclear={parsed.nuclear} gas={parsed.gas} storage={parsed.storage} />
    </div>
  );
};

export default PrivacyPolicy;
