import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { X } from 'react-feather';

import './MenuBar.css';

const MenuBar = ({ visible, setVisible, wind, solar, nuclear, storage, gas }) => {
  return(
    <>
      <div className={`menu p-8 bg-midnight-blue${visible ? ' menu-show': ''}`} data-test-id="menu">
        <div className="flex inline-flex pb-8 text-white" onClick={() => setVisible(false)}>
          <X />
          Close
        </div>
        <div className="flex flex-col">
          <Link to={`/keep-the-lights-on?wind=${wind}&solar=${solar}&nuclear=${nuclear}&storage=${storage}&gas=${gas}`}
            className="px-4 py-2 underline cursor-pointer text-white" data-test-id="keep-the-lights-on-link">
            Keep The Lights On
          </Link>
          <Link to={`/tour?wind=${wind}&solar=${solar}&nuclear=${nuclear}&storage=${storage}&gas=${gas}`}
            className="px-4 py-2 underline cursor-pointer text-white" data-test-id="tour">
            Return to Tour
          </Link>
          <Link to={`/faqs?wind=${wind}&solar=${solar}&nuclear=${nuclear}&storage=${storage}&gas=${gas}`}
            className="px-4 py-2 underline cursor-pointer text-white" data-test-id="faqs-link">
            FAQs
          </Link>
          <Link to={`/disclaimer?wind=${wind}&solar=${solar}&nuclear=${nuclear}&storage=${storage}&gas=${gas}`}
            className="px-4 py-2 underline cursor-pointer text-white" data-test-id="disclaimer-link">
            Disclaimers
          </Link>
          <Link to={`/privacy-policy?wind=${wind}&solar=${solar}&nuclear=${nuclear}&storage=${storage}&gas=${gas}`}
            className="px-4 py-2 underline cursor-pointer text-white" data-test-id="privacy-policy-link">
            Privacy Policy
          </Link>
        </div>
      </div>
    </>
  );
};

MenuBar.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  wind: PropTypes.number,
  solar: PropTypes.number,
  nuclear: PropTypes.number,
  storage: PropTypes.number,
  gas: PropTypes.number
};

export default MenuBar;
