import React from 'react';
import ReactTooltip from 'react-tooltip';

const EnergySourceTooltips = () => {
  return (
    <>
      <ReactTooltip className="w-64 opaque" id="info-Wind" type="light" effect="solid" place="right" border={true}
        borderColor={'black'} multiline={true}>
        Maximum capacity is 180 GW, enough to supply the UK’s demand, but this generation is driven by wind speed, not
        demand. Over reliance on variable sources could see the costs of balancing the system increase. We need a
        diverse mix of variable and firm low-carbon power.
      </ReactTooltip>
      <ReactTooltip className="w-64 opaque" id="info-Solar" type="light" effect="solid" place="right" border={true}
        borderColor={'black'} multiline={true}>
        The maximum capacity is 400 GW. This is enough to supply the UK’s demand, but this generation is driven by the
        level of sunlight, not demand. Solar alone is not enough to keep the lights on all the time. This level of solar
        would require an area three times the size of Suffolk to be covered with solar panels. We need a diverse mix of
        variable and firm low-carbon power.
      </ReactTooltip>
      <ReactTooltip className="w-64 opaque" id="info-Nuclear" type="light" effect="solid" place="right" border={true}
        borderColor={'black'} multiline={true}>
        The maximum capacity is 36 GW. This is enough to supply 83% of the UK’s demand. This is close to the highest
        share of energy coming from nuclear anywhere in the world. This limit reflects the available nuclear licensed
        sites in the UK. We need an optimal mix of variable and firm low-carbon power.
      </ReactTooltip>
      <ReactTooltip className="w-64 opaque" id="info-Gas" type="light" effect="solid" place="right" border={true}
        borderColor={'black'} multiline={true}>
        The maximum capacity is 60 GW. This is enough energy to supply the UK’s peak demand. But using gas will lead to
        high carbon emissions. Instead, we need an optimal mix of variable and firm low-carbon power.
      </ReactTooltip>
      <ReactTooltip className="w-48 opaque" id="info-mobile-Wind" type="light" effect="solid" place="top" border={true}
        borderColor={'black'} multiline={true}>
        Maximum capacity is 180 GW, enough to supply the UK’s demand, but this generation is driven by wind speed, not
        demand. Over reliance on variable sources could see the costs of balancing the system increase. We need a
        diverse mix of variable and firm low-carbon power.
      </ReactTooltip>
      <ReactTooltip className="w-48 opaque" id="info-mobile-Solar" type="light" effect="solid" place="top" border={true}
        borderColor={'black'} multiline={true}>
        The maximum capacity is 400 GW. This is enough to supply the UK’s demand, but this generation is driven by the
        level of sunlight, not demand. Solar alone is not enough to keep the lights on all the time. This level of solar
        would require an area three times the size of Suffolk to be covered with solar panels. We need a diverse mix of
        variable and firm low-carbon power.
      </ReactTooltip>
      <ReactTooltip className="w-48 opaque" id="info-mobile-Nuclear" type="light" effect="solid" place="top" border={true}
        borderColor={'black'} multiline={true}>
        The maximum capacity is 36 GW. This is enough to supply 83% of the UK’s demand. This is close to the highest
        share of energy coming from nuclear anywhere in the world. This limit reflects the available nuclear licensed
        sites in the UK. We need an optimal mix of variable and firm low-carbon power.
      </ReactTooltip>
      <ReactTooltip className="w-48 opaque" id="info-mobile-Gas" type="light" effect="solid" place="top" border={true}
        borderColor={'black'} multiline={true}>
        The maximum capacity is 60 GW. This is enough energy to supply the UK’s peak demand. But using gas will lead to
        high carbon emissions. Instead, we need an optimal mix of variable and firm low-carbon power.
      </ReactTooltip>
    </>
  );
};

export default EnergySourceTooltips;