import DEMAND from '../demand.json';
import LABELS from './labels.json';

export const getWind = (capacity) => {
  return ({
    'label': 'Wind Energy Produced',
    'fill': true,
    'lineTension': 0.1,
    'backgroundColor': 'rgba(89, 192, 99, 1)',
    'borderColor': 'rgba(89, 192, 99, 1)',
    'borderWidth': 0,
    'borderCapStyle': 'butt',
    'borderDash': [],
    'borderDashOffset': 0.0,
    'borderJoinStyle': 'miter',
    'pointBorderColor': 'rgba(89, 192, 99, 1)',
    'pointBackgroundColor': 'rgba(89, 192, 99, 1)',
    'pointBorderWidth': 1,
    'pointHoverRadius': 0,
    'pointHoverBackgroundColor': 'rgba(89, 192, 99, 1)',
    'pointHoverBorderColor': 'rgba(89, 192, 99, 1)',
    'pointHoverBorderWidth': 1,
    'pointRadius': 0,
    'pointHitRadius': 1,
    'data': [0.16, 0.14, 0.14, 0.12, 0.07, 0.04, 0.03, 0.03, 0.05, 0.11, 0.18, 0.24, 0.31, 0.36, 0.47, 0.59, 0.65, 0.68, 0.71, 0.69, 0.69, 0.68, 0.62, 0.58, 0.57, 0.50, 0.47, 0.45, 0.43, 0.44, 0.48, 0.50, 0.48, 0.46, 0.40, 0.36, 0.34, 0.33, 0.37, 0.34, 0.35, 0.37, 0.46, 0.62, 0.70, 0.66, 0.57, 0.60, 0.56, 0.50, 0.41, 0.41, 0.35, 0.23, 0.16, 0.14, 0.15, 0.16, 0.25, 0.33, 0.44, 0.51, 0.61, 0.66, 0.65, 0.63, 0.66, 0.71, 0.70, 0.66, 0.60, 0.51, 0.40, 0.36, 0.28, 0.20, 0.16, 0.14, 0.14, 0.16, 0.18, 0.17, 0.16, 0.09].map(x => x * capacity)
  });
};

const wind = (capacity) => ({
  'labels': LABELS,
  'datasets': [
    DEMAND,
    getWind(capacity)
  ]
});

export default wind;
