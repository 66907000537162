import React from 'react';
import PropTypes from 'prop-types';

import './EnergyStatus.css';

const EnergyStatus = ({ energyMissing, energyExcess }) => {
  return (
    <div className="flex flex-col" data-test-id="energy-status">
      <div className="font-semibold text-sm text-center">Energy Demand</div>
      <div className="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9">
          <g fill="#17242A" fillRule="evenodd">
            <rect width="2" height="2" x="3" y="7" />
            <polygon points="4 4 8 0 0 0" />
          </g>
        </svg>
      </div>
      <div className="flex flex-row items-stretch bg-white">
        <div className="w-full h-8 relative border border-midnight-blue rounded-sm rounded-r-none">
          <div className="flex h-full stripes--red border border-white items-stretch">
            <div className="bg-white" style={{ width: 100 - energyMissing + '%' }} />
            <div className="absolute bg-white font-bold text-sm m-1 px-1">{ energyMissing }<span className="font-medium text-xs">%</span></div>
          </div>
        </div>
        <div className="w-full relative border border-midnight-blue rounded-sm rounded-l-none">
          <div className="absolute right-0 bg-white rounded-sm right-0 font-bold text-sm m-1 px-1">{ energyExcess }<span className="font-medium text-xs">%</span></div>
          <div className="flex h-full stripes--blue border border-white" style={{ width: energyExcess + '%' }} />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-full font-medium text-xs">Missing</div>
        <div className="">
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9">
            <g fill="#17242A" fillRule="evenodd">
              <rect width="2" height="2" x="3" />
              <polygon points="4 9 8 5 0 5" transform="matrix(1 0 0 -1 0 14)" />
            </g>
          </svg>
        </div>
        <div className="w-full font-medium text-xs text-right">Excess</div>
      </div>
    </div>
  );
};

EnergyStatus.propTypes = {
  energyMissing: PropTypes.number,
  energyExcess: PropTypes.number
};

export default EnergyStatus;
