import React from 'react';

export default () =>
  <>
    <div className="md:w-5/12">
      <h1 className="text-3xl font-bold" data-test-id="tour-card-title">The UK&apos;s electricity demand</h1>
      <div data-test-id="tour-card-blurb">
        <p className="mt-4">
          Demand for energy changes throughout the week. The weather, economic activity and people&apos;s lifestyle
          choices all affect demand.
        </p>
        <p className="mt-4">
          This chart shows how one electricity source produces energy over a week and compares that to the total energy
          demand of the UK.
        </p>
        <p className="mt-4">
          As mentioned, a source will not produce power at its maximum all the time. The average amount of electricity
          produced by a source is know as the &quot;capacity factor&quot;.
        </p>
        <p className="mt-4">
          Solar has an average capacity factor of 10%, meaning on average it produces at 10% of its built capacity.
          Nuclear has an average capacity factor of 90%.
        </p>
        <p className="mt-4">
          This game is modelled on current levels of UK demand.
        </p>
      </div>
    </div>
    <div className="md:w-5/12">
      <img className="w-full" data-test-id="tour-card-image" src="/assets/demand-graph.png"
        alt="A line graph illustrating UK demand of energy against the production of energy" />
      <p className="uppercase font-bold" data-test-id="tour-card-explanation">Explanation</p>
      <span className="w-full pt-6 inline-flex">
        <img className="w-1/10" src="/assets/dotted-line.png"
          alt="A line graph illustrating UK demand of energy against the production of energy" />
        <p>The maximum energy a source can produce based on the capacity you&apos;ve set.</p>
      </span>
      <span className="w-1/2 pt-6 inline-flex">
        <img className="w-1/10" src="/assets/blue-area.png"
          alt="A line graph illustrating UK demand of energy against the production of energy" />
        <p>Actual energy produced by a source.</p>
      </span>
      <span className="w-1/2 pt-6 inline-flex">
        <img className="w-1/10" src="/assets/black-line.png"
          alt="A line graph illustrating UK demand of energy against the production of energy" />
        <p>The UK&apos;s total energy demand.</p>
      </span>
    </div>
  </>;