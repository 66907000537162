import DEMAND from '../demand.json';
import LABELS from './labels.json';
import wind from './wind';
import solar from './solar';
import nuclear from './nuclear';
import storage from './storage';

const gasData = (capacity, windCapacity, solarCapacity, nuclearCapacity, storageCapacity) => {
  let gasDemand = [];

  const array = [wind(windCapacity).datasets[1].data,
      solar(solarCapacity).datasets[1].data,
      nuclear(nuclearCapacity).datasets[1].data,
      storage(storageCapacity, windCapacity, solarCapacity, nuclearCapacity).datasets[1].data],
    result = array.reduce((r, a) => a.map((b, i) => (r[i] || 0) + b), []);

  for (let i = 0; i < result.length; i++) {
    gasDemand.push(Math.max(DEMAND.data[i] - result[i] < capacity ? DEMAND.data[i] - result[i] : capacity, 0));
  }

  return gasDemand;
};

export const getGas = (capacity, wind, solar, nuclear, storage) => (
  {
    'label': 'Gas Energy Produced',
    'fill': true,
    'lineTension': 0.1,
    'backgroundColor': 'rgba(66, 77, 78, 1)',
    'borderColor': 'rgba(66, 77, 78, 1)',
    'borderWidth': 0,
    'borderCapStyle': 'butt',
    'borderDash': [],
    'borderDashOffset': 0.0,
    'borderJoinStyle': 'miter',
    'pointBorderColor': 'rgba(66, 77, 78, 1)',
    'pointBackgroundColor': 'rgba(66, 77, 78, 1)',
    'pointBorderWidth': 1,
    'pointHoverRadius': 0,
    'pointHoverBackgroundColor': 'rgba(66, 77, 78, 1)',
    'pointHoverBorderColor': 'rgba(66, 77, 78, 1)',
    'pointHoverBorderWidth': 1,
    'pointRadius': 0,
    'pointHitRadius': 1,
    'data': gasData(capacity, wind, solar, nuclear, storage)
  }
);

const gas = (capacity, wind, solar, nuclear, storage) => ({
  'labels': LABELS,
  'datasets': [
    DEMAND,
    getGas(capacity, wind, solar, nuclear, storage)
  ]
});

export default gas;
