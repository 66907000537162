import React from 'react';
import PropTypes from 'prop-types';

import { allowedCarbonEmissions, peakDemand, sumValues } from '../../utils/utils';

const getHint = (averageMixData, lowMixData, setHintModalVisible) => {
  let hint;

  if (averageMixData.carbon >= allowedCarbonEmissions || lowMixData.carbon >= allowedCarbonEmissions) {
    hint = (
      <div className="text-center">
        <h1 className="font-black text-2xl md:text-4xl">You&apos;re not currently Net Zero!</h1>
        <p>
          Unfortunately, you have not met the Net Zero requirement for 2050. Your selected mix is still too carbon
          intensive. It may, for example, rely on volumes of gas which, until Carbon Capture and Storage (CCS) is proved
          commercially viable at scale, release greenhouse gas emissions into the atmosphere.
        </p>
      </div>
    );
  } else if (
    (averageMixData.mix.wind === 0 && averageMixData.mix.solar === 0 && averageMixData.mix.nuclear !== 0) ||
      (averageMixData.mix.wind === 0 && averageMixData.mix.nuclear === 0 && averageMixData.mix.solar !== 0) ||
      (averageMixData.mix.solar === 0 && averageMixData.mix.nuclear === 0 && averageMixData.mix.wind !== 0)
  ) {
    hint = (
      <div className="text-center">
        <h1 className="font-black text-2xl md:text-4xl">Diversify your mix</h1>
        <p>
          As with anything, it’s safer not to put all your eggs in one basket. A balanced and diversified mix is the
          best way to meet Net Zero and keep the lights on.
        </p>
      </div>
    );
  } else if (
    (averageMixData.mix.wind !== 0 && averageMixData.mix.nuclear !== 0 && averageMixData.mix.storage === 0) ||
      (averageMixData.mix.solar !== 0 && averageMixData.mix.nuclear !== 0 && averageMixData.mix.storage === 0)
  ) {
    hint = (
      <div className="text-center">
        <h1 className="font-black text-2xl md:text-4xl">Smooth the peaks and troughs</h1>
        <p>
          Try adding some storage to help smooth out the peaks and troughs in electricity production. Excess power will
          be stored for use when power output doesn&apos;t meet demand.
        </p>
      </div>
    );
  } else if (sumValues(averageMixData.mix) < peakDemand ) {
    hint = (
      <div className="text-center">
        <h1 className="font-black text-2xl md:text-4xl">Add some capacity!</h1>
        <p>
          Some generating technologies are “firm”, providing regular uninterrupted power, like nuclear and gas whilst
          others are variable, like solar and wind.
        </p>
      </div>
    );
  } else if (sumValues(averageMixData.mix) >= peakDemand && averageMixData.energy.missing !== 0) {
    hint = (
      <div className="text-center">
        <h1 className="font-black text-2xl md:text-4xl">You&apos;re almost there!</h1>
        <p>
          You&apos;ve built the capacity to generate enough power to supply peak demand.
          So, <span className="font-bold underline cursor-pointer" onClick={() => setHintModalVisible(true)}>why
          aren&apos;t all the lights on?</span>
        </p>
      </div>
    );
  } else if (averageMixData.energy.missing === 0
    && averageMixData.carbon <= allowedCarbonEmissions
    && lowMixData.energy.missing === 0
    && lowMixData.carbon <= allowedCarbonEmissions) {
    hint = (
      <div className="text-center">
        <h1 className="font-black text-2xl md:text-4xl">Congratulations!</h1>
        <p>
          You&apos;ve created an energy mix that can keep the lights on even in more challenging conditions and
          you&apos;ve met Net Zero! If your system cost is high you could try to lower it by reducing expensive
          overbuild capacity or storage. To make sure the lights stay on and you meet Net Zero, you might need to add
          more firm low carbon power.
        </p>
      </div>
    );
  } else if (averageMixData.energy.missing === 0 && averageMixData.carbon <= allowedCarbonEmissions) {
    hint = (
      <div className="text-center">
        <h1 className="font-black text-2xl md:text-4xl">Next challenge!</h1>
        <p>
          You&apos;ve created an energy mix that keeps the lights on during an average week! Now you&apos;ve managed
          that, try a low sun and low wind week. Good luck with week two!
        </p>
      </div>
    );
  }
  return hint;
};

const HintText = ({ averageMixData, lowMixData, setHintModalVisible }) => {
  return (
    <div className="text-center m-4 md:my-16" data-test-id="hint-text">
      {getHint(averageMixData, lowMixData, setHintModalVisible)}
    </div>
  );
};

HintText.propTypes = {
  averageMixData: PropTypes.object,
  lowMixData: PropTypes.object,
  setHintModalVisible: PropTypes.func
};

export default HintText;
