import React from 'react';
import CloudyWeekLogo from '../weather/CloudyWeekLogo';

const WeekTwoPlaceholder = () => {
  return (
    <>
      <div className="my-2">
        <div className="flex flex-row">
          <div className="mr-4">
            <CloudyWeekLogo className="low-week-logo-on" />
          </div>
          <div className="text-left text-sm">
            <p className="text-sm"><span className="font-bold">Week Two</span><br />
                  Low sun, low wind</p>
          </div>
        </div>
      </div>
      <p className="mb-2 text-sm font-bold text-center">Demand vs output</p>
      <div className="border border-gray-300 bg-gray-100 rounded-sm p-4 ">
        <p className="text-center">
          You need to be able to meet the demand for electricity in Week One before attempting Week Two.
        </p>
      </div>
    </>
  );
};

export default WeekTwoPlaceholder;
