import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { ArrowDown, Facebook, Linkedin, Mail, Twitter } from 'react-feather';

import Leaderboard from '../leaderboard/Leaderboard';

import './SocialShare.css';

const SocialShare = ({ score }) => {
  const [leaderboardVisible, setLeaderboardVisible] = useState(false);
  const display = leaderboardVisible ? 'leaderboard--visible' : 'leaderboard--hidden';

  return (
    <>
      <div className="bg-midnight-blue md:flex justify-between pt-4 p-2 mt-8">
        <div className="bg-neon-yellow md:w-2/3 parallelogram" onClick={() => setLeaderboardVisible(!leaderboardVisible)}>
          <div className="parallelogram-content flex">
            <div className="leaderboard-button" data-test-id="expand-leaderboard">
              <ArrowDown size={32} />
            </div>
            <div>
              <h3 className="font-bold">Click to view the leaderboard and share your results</h3>
              <p>Tell your colleagues and friends about the game and see if they can beat your score.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row pt-4 md:justify-end md:pt-0">
          <div className="ml-4 mr-4">
            <EmailShareButton subject="Net Zero Energy Challenge"
              body={`I scored ${score}% and managed to keep the lights on with net-zero carbon! Can you?`}
              url={window.location.href.substring(0, window.location.href.indexOf(window.location.pathname))}
            >
              <Mail color="#f7ff46" />
            </EmailShareButton>
          </div>
          <div className="ml-4 mr-4">
            <LinkedinShareButton url="energymixer.uk">
              <Linkedin color="#f7ff46" />
            </LinkedinShareButton>
          </div>
          <div className="ml-4 mr-4">
            <TwitterShareButton
              title={`I scored ${score}% and managed to keep the lights on with net-zero carbon! Can you?`}
              url={window.location.href.substring(0, window.location.href.indexOf(window.location.pathname))}
            >
              <Twitter color="#f7ff46" />
            </TwitterShareButton>
          </div>
          <div className="ml-4 mr-4">
            <FacebookShareButton
              quote={`I scored ${score}% and managed to keep the lights on with net-zero carbon! Can you?`}
              url={window.location.href.substring(0, window.location.href.indexOf(window.location.pathname))}
            >
              <Facebook color="#f7ff46" />
            </FacebookShareButton>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <Leaderboard display={display} userScore={score} />
      </div>
    </>
  );
};

SocialShare.propTypes = {
  score: PropTypes.number
};

export default SocialShare;
