import React from 'react';

const LoadFactorTable = () => {
  return (
    <table className="table-auto border-collapse border-2 w-full my-4">
      <thead>
        <tr className="bg-gray-100">
          <th className="border px-4 py-2">Technology</th>
          <th className="border px-4 py-2">Week 1</th>
          <th className="border px-4 py-2">Week 2</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border px-4 py-2">Wind</td>
          <td className="border px-4 py-2">39%</td>
          <td className="border px-4 py-2">21%</td>
        </tr>
        <tr>
          <td className="border px-4 py-2">Solar</td>
          <td className="border px-4 py-2">10%</td>
          <td className="border px-4 py-2">3%</td>
        </tr>
        <tr>
          <td className="border px-4 py-2">Nuclear</td>
          <td className="border px-4 py-2">88%</td>
          <td className="border px-4 py-2">78%</td>
        </tr>
      </tbody>
    </table>
  );
};

export default LoadFactorTable;
