import React from 'react';
import PropTypes from 'prop-types';

const Battery = ({ gigawattHours, purple }) => {
  return (
    <div className="battery-storage w-full">
      <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="80" height="80" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15 66.7547C15 73.5905 26.0831 79.1321 39.7547 79.1321C53.4264 79.1321 64.5094 73.5905 64.5094 66.7547V13.3774C64.5094 6.54153 53.4264 1 39.7547 1C26.0831 1 15 6.54153 15 13.3774V66.7547Z" fill="white" stroke="#17242A" strokeWidth="0.604" />
        <g className={gigawattHours < 0 ? 'hidden': ''}>
          <path d="M39.603 79.094C52.1907 79.094 62.395 73.9578 62.395 67.622C62.395 61.2862 52.1907 56.15 39.603 56.15C27.0154 56.15 16.811 61.2862 16.811 67.622C16.811 73.9578 27.0154 79.094 39.603 79.094Z" fill={`${purple ? '#B05DD4' : '#B2FEF7'}`} />
        </g>
        <g className={gigawattHours < 500 ? 'hidden': ''}>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.811 66.2443C16.811 72.58 27.0156 77.716 39.6035 77.716C52.1914 77.716 62.3959 72.58 62.3959 66.2443V62.793H16.811V66.2443Z" fill={`${purple ? '#9400D3' : '#65FEF0'}`} />
          <path d="M39.603 74.094C52.1907 74.094 62.395 68.9578 62.395 62.622C62.395 56.2862 52.1907 51.15 39.603 51.15C27.0154 51.15 16.811 56.2862 16.811 62.622C16.811 68.9578 27.0154 74.094 39.603 74.094Z" fill={`${purple ? '#B05DD4' : '#B2FEF7'}`} />
        </g>
        <g className={gigawattHours < 1000 ? 'hidden': ''}>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.811 60.8113C16.811 67.147 27.0156 72.283 39.6035 72.283C52.1914 72.283 62.3959 67.147 62.3959 60.8113V57.36H16.811V60.8113Z" fill={`${purple ? '#9400D3' : '#65FEF0'}`} />
          <path d="M39.603 68.661C52.1907 68.661 62.395 63.5249 62.395 57.189C62.395 50.8532 52.1907 45.717 39.603 45.717C27.0154 45.717 16.811 50.8532 16.811 57.189C16.811 63.5249 27.0154 68.661 39.603 68.661Z" fill={`${purple ? '#B05DD4' : '#B2FEF7'}`} />
        </g>
        <g className={gigawattHours < 1500 ? 'hidden': ''}>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.811 55.3773C16.811 61.7129 27.0156 66.849 39.6035 66.849C52.1914 66.849 62.3959 61.7129 62.3959 55.3773V51.9259H16.811V55.3773Z" fill={`${purple ? '#9400D3' : '#65FEF0'}`} />
          <path d="M39.603 63.227C52.1907 63.227 62.395 58.0908 62.395 51.755C62.395 45.4191 52.1907 40.283 39.603 40.283C27.0154 40.283 16.811 45.4191 16.811 51.755C16.811 58.0908 27.0154 63.227 39.603 63.227Z" fill={`${purple ? '#B05DD4' : '#B2FEF7'}`} />
        </g>
        <g className={gigawattHours < 2000 ? 'hidden': ''}>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.811 49.9443C16.811 56.2799 27.0156 61.416 39.6035 61.416C52.1914 61.416 62.3959 56.2799 62.3959 49.9443V46.4929H16.811V49.9443Z" fill={`${purple ? '#9400D3' : '#65FEF0'}`} />
          <path d="M39.603 57.794C52.1907 57.794 62.395 52.6578 62.395 46.322C62.395 39.9862 52.1907 34.85 39.603 34.85C27.0154 34.85 16.811 39.9862 16.811 46.322C16.811 52.6578 27.0154 57.794 39.603 57.794Z" fill={`${purple ? '#B05DD4' : '#B2FEF7'}`} />
        </g>
        <g className={gigawattHours < 2500 ? 'hidden': ''}>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.811 44.5093C16.811 50.845 27.0156 55.981 39.6035 55.981C52.1914 55.981 62.3959 50.845 62.3959 44.5093V41.058H16.811V44.5093Z" fill={`${purple ? '#9400D3' : '#65FEF0'}`} />
          <path d="M39.603 52.359C52.1907 52.359 62.395 47.2229 62.395 40.887C62.395 34.5512 52.1907 29.415 39.603 29.415C27.0154 29.415 16.811 34.5512 16.811 40.887C16.811 47.2229 27.0154 52.359 39.603 52.359Z" fill={`${purple ? '#B05DD4' : '#B2FEF7'}`} />
        </g>
        <g className={gigawattHours < 3000 ? 'hidden': ''}>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.811 39.0753C16.811 45.4109 27.0156 50.547 39.6035 50.547C52.1914 50.547 62.3959 45.4109 62.3959 39.0753V35.6239H16.811V39.0753Z" fill={`${purple ? '#9400D3' : '#65FEF0'}`} />
          <path d="M39.603 46.925C52.1907 46.925 62.395 41.7888 62.395 35.453C62.395 29.1171 52.1907 23.981 39.603 23.981C27.0154 23.981 16.811 29.1171 16.811 35.453C16.811 41.7888 27.0154 46.925 39.603 46.925Z" fill={`${purple ? '#B05DD4' : '#B2FEF7'}`} />
        </g>
        <g className={gigawattHours < 4000 ? 'hidden': ''}>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.811 33.6413C16.811 39.9769 27.0156 45.113 39.6035 45.113C52.1914 45.113 62.3959 39.9769 62.3959 33.6413V30.1899H16.811V33.6413Z" fill={`${purple ? '#9400D3' : '#65FEF0'}`} />
          <path d="M39.603 41.491C52.1907 41.491 62.395 36.3548 62.395 30.019C62.395 23.6832 52.1907 18.547 39.603 18.547C27.0154 18.547 16.811 23.6832 16.811 30.019C16.811 36.3548 27.0154 41.491 39.603 41.491Z" fill={`${purple ? '#B05DD4' : '#B2FEF7'}`} />
        </g>
        <g className={gigawattHours < 5000 ? 'hidden': ''}>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.811 28.2073C16.811 34.543 27.0156 39.679 39.6035 39.679C52.1914 39.679 62.3959 34.543 62.3959 28.2073V24.756H16.811V28.2073Z" fill={`${purple ? '#9400D3' : '#65FEF0'}`} />
          <path d="M39.603 36.057C52.1907 36.057 62.395 30.9208 62.395 24.585C62.395 18.2492 52.1907 13.113 39.603 13.113C27.0154 13.113 16.811 18.2492 16.811 24.585C16.811 30.9208 27.0154 36.057 39.603 36.057Z" fill={`${purple ? '#B05DD4' : '#B2FEF7'}`} />
        </g>
        <g className={gigawattHours < 6000 ? 'hidden': ''}>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.811 22.7743C16.811 29.11 27.0156 34.246 39.6035 34.246C52.1914 34.246 62.3959 29.11 62.3959 22.7743V19.323H16.811V22.7743Z" fill={`${purple ? '#9400D3' : '#65FEF0'}`} />
          <path d="M39.603 30.6241C52.1907 30.6241 62.395 25.4879 62.395 19.1521C62.395 12.8162 52.1907 7.68005 39.603 7.68005C27.0154 7.68005 16.811 12.8162 16.811 19.1521C16.811 25.4879 27.0154 30.6241 39.603 30.6241Z" fill={`${purple ? '#B05DD4' : '#B2FEF7'}`} />
        </g>
        <g className={gigawattHours < 7000 ? 'hidden': ''}>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.811 17.3393C16.811 23.6749 27.0156 28.811 39.6035 28.811C52.1914 28.811 62.3959 23.6749 62.3959 17.3393V13.8879H16.811V17.3393Z" fill={`${purple ? '#9400D3' : '#65FEF0'}`} />
          <path d="M39.603 25.189C52.1907 25.189 62.395 20.0528 62.395 13.717C62.395 7.38118 52.1907 2.245 39.603 2.245C27.0154 2.245 16.811 7.38118 16.811 13.717C16.811 20.0528 27.0154 25.189 39.603 25.189Z" fill={`${purple ? '#B05DD4' : '#B2FEF7'}`} />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M15 13.0754C15 20.078 26.0831 25.7547 39.7547 25.7547C53.4264 25.7547 64.5094 20.078 64.5094 13.0754" fill="white" />
        <path d="M15 13.0754C15 20.078 26.0831 25.7547 39.7547 25.7547C53.4264 25.7547 64.5094 20.078 64.5094 13.0754" stroke="#17242A" strokeWidth="0.604" />
        <path fillRule="evenodd" clipRule="evenodd" d="M51.468 27.566V30.919L54.849 30.121V32.995L51.468 33.793V37.146L48.57 37.83V34.477L45.189 35.275V32.401L48.57 31.603V28.25L51.468 27.566Z" fill="#17242A" />
        <path fillRule="evenodd" clipRule="evenodd" d="M51.226 64.83V74.41L48.208 75.094V65.514L51.226 64.83Z" fill="#17242A" />
        <path d="M39.7548 23.038C51.926 23.038 61.7928 18.4427 61.7928 12.774C61.7928 7.10536 51.926 2.51001 39.7548 2.51001C27.5835 2.51001 17.7168 7.10536 17.7168 12.774C17.7168 18.4427 27.5835 23.038 39.7548 23.038Z" fill="#E8E8E8" stroke="#17242A" strokeWidth="0.604" />
        <path fillRule="evenodd" clipRule="evenodd" d="M27.0757 11.2642V13.5166C27.0757 16.941 32.6172 19.717 39.453 19.717C46.2889 19.717 51.8304 16.941 51.8304 13.5166V11.2642" fill="white" />
        <path d="M27.0757 11.2642V13.5166C27.0757 16.941 32.6172 19.717 39.453 19.717C46.2889 19.717 51.8304 16.941 51.8304 13.5166V11.2642" stroke="#17242A" strokeWidth="0.5" />
        <path d="M39.4532 17.906C46.2888 17.906 51.8302 15.0674 51.8302 11.566C51.8302 8.06447 46.2888 5.22595 39.4532 5.22595C32.6175 5.22595 27.0762 8.06447 27.0762 11.566C27.0762 15.0674 32.6175 17.906 39.4532 17.906Z" fill="#E8E8E8" stroke="#17242A" strokeWidth="0.5" />
      </svg>

    </div>
  );
};

Battery.propTypes = {
  gigawattHours: PropTypes.number,
  purple: PropTypes.bool
};

export default Battery;
