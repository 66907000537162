import React from 'react';
import PropTypes from 'prop-types';

import './Leaderboard.css';

const Leaderboard = ({ display, userScore }) => {
  // Fictional scores
  const results =
    [
      { name:'Elizabeth', score: 86 },
      { name:'Charles', score: 85 },
      { name:'Catherine', score: 84 },
      { name:'William', score: 82 },
      { name:'Megan', score: 80 },
      { name:'Harry', score: 75 },
      { name:'Charlotte', score: 70 },
      { name:'George', score: 60 },
      { name:'Archie', score: 55 },
      { name:'You',  score: userScore }
    ];

  results.sort(function(a, b) {
    return parseInt(b.score) - parseInt(a.score);
  });

  return (
    <div className={'bg-midnight-blue pt-4 pb-2 ' + display} data-test-id="leaderboard">
      {results.map((result, index) => (
        <div className="flex flex-row font-bold uppercase m-2 text-neon-yellow"
          key={index}>
          <div className={'w-10/12 ml-2 ' + (result.name === 'You' ? 'leaderboard--user' : '')}>{result.name}</div>
          <div className={'w-2/12 mr-2 text-right ' + (result.name === 'You' ? 'leaderboard--user' : '')}>{result.score}%</div>
        </div>
      ))}
    </div>
  );
};

Leaderboard.propTypes = {
  display: PropTypes.string,
  userScore: PropTypes.number
};

export default Leaderboard;
