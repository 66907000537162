import React from 'react';

const SolarCapacityModal = () => {
  return(
    <div className="justify-between block mx-auto md:p-8">
      <div>
        <h1 className="font-bold text-4xl pb-8">Solar Capacity</h1>
        <p>
                    The maximum capacity is 400 GW. This is enough to supply the UK’s demand, but this generation is
                    driven by the level of sunlight, not demand. Solar alone is not enough to keep the lights on all the
                    time. This level of solar would  require an area three times the size of Suffolk to be covered with
                    solar panels. We need a diverse mix of variable and firm low-carbon power.
        </p>
      </div>
    </div>
  );
};

export default SolarCapacityModal;
