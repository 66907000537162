import React from 'react';
import PropTypes from 'prop-types';

import { allowedCarbonEmissions } from '../../utils/utils';

function mixWins(averageMixData, lowMixData) {
  return averageMixData.energy.missing === 0
    && averageMixData.carbon <= allowedCarbonEmissions
    && lowMixData.energy.missing === 0
    && lowMixData.carbon <= allowedCarbonEmissions;
}

const reevaluate = (averageMixData, lowMixData, setSummaryVisible) => {
  if (mixWins(averageMixData, lowMixData))
  {
    setSummaryVisible(true);
  }
};

const ReevaluateButton = ({ averageMixData, lowMixData, setSummaryVisible }) => {
  return (
    <div className={`md:m-0 m-2 md:underline md:text-neon-blue-500 md:font-normal bg-midnight-blue rounded-sm no-underline
    text-white font-bold py-2 px-4 rounded-sm ${mixWins(averageMixData, lowMixData) ? '' : 'opacity-75 cursor-not-allowed'}`}
    data-test-id="reevaluate-mix-button"
    onClick={() => reevaluate(averageMixData, lowMixData, setSummaryVisible)}
    >
      Score my mix
    </div>
  );
};

ReevaluateButton.propTypes = {
  averageMixData: PropTypes.object,
  lowMixData: PropTypes.object,
  setSummaryVisible: PropTypes.func
};

export default ReevaluateButton;
