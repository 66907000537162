import React from 'react';

const NonNetZeroCarbonCloud = () => {
  return (
    <svg className="ml-4 mr-4 mt-1" width="102" height="88" viewBox="0 0 102 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.32" fillRule="evenodd" clipRule="evenodd" d="M80.9835 78.3736C75.8671 78.3736 71.1776 76.5453 67.5329 73.5063C63.8882 76.5453 59.1987 78.3736 54.0822 78.3736C48.9344 78.3736 44.2188 76.5228 40.5646 73.4502C36.3438 76.5456 31.1354 78.3737 25.5 78.3737C11.4167 78.3737 0 66.9569 0 52.8737C0 38.9511 11.1577 27.6346 25.0189 27.3781C26.8584 17.488 35.5327 10 45.9559 10C53.5316 10 60.1834 13.9556 63.9587 19.914C66.8188 18.2432 70.1465 17.2857 73.6978 17.2857C84.3764 17.2857 93.033 25.9424 93.033 36.6209C93.033 37.7527 92.9358 38.8617 92.7492 39.9402C98.3311 43.7183 102 50.1093 102 57.3571C102 68.9642 92.5906 78.3736 80.9835 78.3736Z" fill="white" />
      <path d="M43.2273 46.4545C42.7273 40.6818 38.5 37.4091 32.6364 37.4091C26.3636 37.4091 21.4091 41.5909 21.4091 49.3636C21.4091 57.1023 26.2273 61.3182 32.6364 61.3182C39.4545 61.3182 42.9091 56.5909 43.2273 52.6364L36.8182 52.5909C36.5 54.5795 35.0909 55.8182 32.7727 55.8182C29.6932 55.8182 27.9091 53.6364 27.9091 49.3636C27.9091 45.3182 29.6364 42.9091 32.8182 42.9091C35.25 42.9091 36.6364 44.3409 36.8182 46.4545H43.2273ZM68.6392 49.3636C68.6392 41.5909 63.6392 37.4091 57.321 37.4091C50.9574 37.4091 46.0028 41.5909 46.0028 49.3636C46.0028 57.0909 50.9574 61.3182 57.321 61.3182C63.6392 61.3182 68.6392 57.1364 68.6392 49.3636ZM62.1392 49.3636C62.1392 53.5455 60.4574 55.8182 57.321 55.8182C54.1847 55.8182 52.5028 53.5455 52.5028 49.3636C52.5028 45.1818 54.1847 42.9091 57.321 42.9091C60.4574 42.9091 62.1392 45.1818 62.1392 49.3636Z" fill="#17242A" />
      <path d="M71.8693 63H81.9176V60.1619H76.804V60.0852L78.0312 59.0625C81.0163 56.576 81.777 55.2848 81.777 53.7699C81.777 51.3281 79.7763 49.7301 76.6378 49.7301C73.5888 49.7301 71.6072 51.424 71.6136 54.179H74.9886C74.9822 53.0795 75.6534 52.4659 76.6378 52.4659C77.6158 52.4659 78.3125 53.0732 78.3125 54.0767C78.3125 55.0036 77.718 55.6044 76.7528 56.4034L71.8693 60.4432V63Z" fill="#17242A" />
      <circle opacity="0.32" cx="12" cy="18" r="4" fill="white" />
      <circle opacity="0.32" cx="94" cy="19" r="4" fill="white" />
      <circle opacity="0.32" cx="79" cy="3" r="2" fill="white" />
      <circle opacity="0.32" cx="41" cy="81" r="2" fill="white" />
      <circle opacity="0.32" cx="60" cy="86" r="2" fill="white" />
      <circle opacity="0.32" cx="53" cy="2" r="2" fill="white" />
      <circle opacity="0.32" cx="83" cy="14" r="2" fill="white" />
      <circle opacity="0.32" cx="29.5659" cy="6.56597" r="2" transform="rotate(-20.1196 29.5659 6.56597)" fill="white" />
      <circle opacity="0.32" cx="22" cy="16" r="2" fill="white" />
      <circle opacity="0.32" cx="2" cy="12" r="2" fill="white" />
      <circle opacity="0.32" cx="71" cy="10" r="4" fill="white" />
    </svg>
  );
};

export default NonNetZeroCarbonCloud;
