import React from 'react';
import PropTypes from 'prop-types';
import TypicalWeekLogo from '../weather/TypicalWeekLogo';
import CloudyWeekLogo from '../weather/CloudyWeekLogo';

const SummaryWeather = ({ week }) => {
  return (
    <div className="flex flex-row">
      { week === 'one' && (
        <>
          <div className="hidden md:block mr-4">
            <TypicalWeekLogo />
          </div>
          <div className="text-left text-sm">
            <p className="text-sm"><span className="font-bold">Week One</span><br />
              Average sun, average wind</p>
          </div>
        </>
      )}
      { week === 'two' && (
        <>
          <div className="hidden md:block mr-4">
            <CloudyWeekLogo />
          </div>
          <div className="text-left text-sm">
            <p className="text-sm"><span className="font-bold">Week Two</span><br />
              Low sun, low wind</p>
          </div>
        </>
      )}
    </div>
  );
};

SummaryWeather.propTypes = {
  week: PropTypes.oneOf(['one', 'two'])
};

export default SummaryWeather;
