import React, { useState } from 'react';
import queryString from 'query-string';
import { Menu } from 'react-feather';

import MenuBar from '../menu/MenuBar';
import LoadFactorTable from '../loadFactorTable/LoadFactorTable';

import './Disclaimer.css';

const Disclaimer = () => {

  const [menuVisible, setMenuVisible] = useState(false);
  const parsed = queryString.parse(window.location.search);

  return (
    <div id="disclaimer-body" className="disclaimer-body md:m-auto pt-8 ml-4 mr-4" data-test-id="disclaimer-container">
      <div className="h-12 flex flex-row my-2" data-test-id="menu-btn" onClick={() => setMenuVisible(true)}>
        <Menu />
        <p className="uppercase font-bold ml-4">Menu</p>
      </div>
      <h1 className="font-bold text-3xl">Disclaimer</h1>
      <ol className="ml-4 list-decimal">
        <li className="p-2">
          The data used in this tool is taken from publicly available data on
          electricity demand and generation in GB and represents two discrete data
          sets from BMReports.com (this is the public Balancing Mechanism
          Reporting Service (BMRS) website maintained by the Balancing
          Mechanism Reporting Agent (BMRA)), providing a set of half-hourly data points.
          As this data source only represents Great Britain, Northern Ireland has been
          excluded for the purposes of this game. Northern Ireland electricity is part
          of a separate electricity system.
        </li>
        <li className="p-2">
          For performance reasons, this data has been sampled and averaged with a
          granularity of 2 hours for each data point rather than half-hourly data
          points.
        </li>
        <li className="p-2">
          Week one illustrates typical weather conditions, when enough sun and
          wind are available to produce an average electricity output from
          renewable sources (from 01/04/2018 to 07/04/2018). Week two
          represents more challenging conditions, cloudy and with low levels of
          wind, restricting renewable energy output, with some outage in the
          nuclear capacity reducing its generation output compared to week one
          (from 01/01/2017 to 07/01/2017). These conditions demonstrate typical
          challenges that the energy grid will have to manage. Weekly demand is set
          at 6.44TWh, which is the energy demand for week 1. Any increase or change
          in the demand profile between weeks has not been considered.
        </li>
        <li className="p-2">Average weekly load factors:
          <LoadFactorTable />
        </li>
        <li className="p-2">
          This game is intended to be a simplified version of the UK energy mix and future technologies have been
          excluded for simplicity. Players will use nuclear power as a sole firm low carbon power generating technology
          rather than combining it with other firm power options, such as natural gas combined with carbon capture
          technology (CCS). Today, large nuclear is the only available technology to provide low carbon firm power in
          the UK at scale. We recognise that, in the future, CCS, but also SMRs and hydrogen can play a role in the
          energy mix provided their commercial viability is proven at scale.<br />
          The capacity for large nuclear power has been limited to 36GW to reflect the expected maximum capacity found
          by the Energy Technologies Institute in their 2015 report, <i>&quot;The role for nuclear within a low carbon energy
          system&quot;</i>. This limit does not include the potential capacity available from SMR or AMR technologies that could
          come in addition. We have limited wind to 180 GW and solar to 400 GW, to reflect current estimates for
          potential build rates up to 2050.<br />
          We intend to include further technologies in future versions of the game once we have data to support their
          implementation and they are proven.<br />
          <i style={{ fontSize: '12px', display: 'inline-table' }}>
            Net Zero – The UK’s contribution to stopping global warming - CCC technical report<br />
            Available at: <a className="font-bold" title="Go to https://www.theccc.org.uk/publication/net-zero-technical-report/ (Opens in a new window)" href="https://www.theccc.org.uk/publication/net-zero-technical-report/" rel="noopener noreferrer" target="_blank">https://www.theccc.org.uk/publication/net-zero-technical-report/</a><br />
            <span className="text-gray-700">[Accessed: June 2020]</span><br />
          </i>
        </li>
        <li className="p-2" id="storage">
          For each player we have included some ‘free-to-use’ storage in the model reflecting the flexibility already
          present in the system (existing pumped storage and batteries). We have also included flexibility likely to
          emerge, based on NIC NIA report (Vehicle to grid, demand side response) 100GWh (2.5 hours) of free storage is
          available which reflects:<br />
          <ul className="ml-8 list-disc">
            <li>
              11GW of Vehicle-to-grid capacity (NIC NIA report), we have assumed it would be made available to the grid
              for 4 hours at approximately 44 GWh
            </li>
            <li>
              7 GW of Demand Side Response capability [NIC NIA report], we have assumed it would be made available to
              the grid for available for 1 hour at approximately 7 GWh
            </li>
            <li>
              Existing pumped storage capacity at approximately 18 GWh
            </li>
            <li>
              Existing Battery storage at negligible contribution
            </li>
            <li>
              Interconnectors. As the system evolves, predicting the use of interconnectors would require pan-European
              modelling. This is beyond the scope and complexity of this game. We have suggested that 35 GWh of pure
              flexibility is available for the user from interconnectors as an approximation, which is roughly double
              the contribution of pumped storage available today. 5 GW of interconnector capacity is available to the UK
              today. 35 GWh is equivalent to importing (or exporting) electricity for 7 hours at maximum capacity, every
              time the UK needs it.
            </li>
          </ul>
          <i style={{ fontSize: '12px', display: 'inline-table' }}>
            National Infrastructure Assessment - National Infrastructure Commission [online]<br />
            Available at: <a className="font-bold" title="Go to https://www.nic.org.uk/wp-content/uploads/CCS001_CCS0618917350-001_NIC-NIA_Accessible.pdf (Opens in a new window)" href="https://www.nic.org.uk/wp-content/uploads/CCS001_CCS0618917350-001_NIC-NIA_Accessible.pdf" rel="noopener noreferrer" target="_blank">https://www.nic.org.uk/wp-content/uploads/CCS001_CCS0618917350-001_NIC-NIA_Accessible.pdf</a><br />
            <span className="text-gray-700">[Accessed: July 2020]</span><br />
          </i>
          <i style={{ fontSize: '12px', display: 'inline-table' }}>
            Power sector modelling: System cost impact of renewables Report for the National Infrastructure Commission – Aurora Energy Research [online]<br />
            Available at: <a className="font-bold" title="Go to https://www.nic.org.uk/wp-content/uploads/Power-sector-modelling-final-report-1-Aurora-Energy-Research.pdf (Opens in a new window)" href="https://www.nic.org.uk/wp-content/uploads/Power-sector-modelling-final-report-1-Aurora-Energy-Research.pdf" rel="noopener noreferrer" target="_blank">https://www.nic.org.uk/wp-content/uploads/Power-sector-modelling-final-report-1-Aurora-Energy-Research.pdf</a><br />
            <span className="text-gray-700">[Accessed: July 2020]</span><br />
          </i>
        </li>
        <li className="p-2">
          Week 1 starts with selected storage at 50% capacity. Week 1 must end with
          the storage at 50% of capacity for the user to progress to week 2. Storage
          will cease to be utilised at the point in the week after which it can no
          longer be charged back to 50% of capacity. Instead, gas is utilised, or
          missing storage is recorded.<br />
          Week 2 allows storage to finish the week empty.<br />
          This difference aims to represent the energy available from storage in the
          context of changing weather conditions. At times of surplus, the storage
          will be relatively full, whilst in more challenging circumstances, fully
          utilised storage energy can be expected.<br />
        </li>
        <li className="p-2">
          The lights shown on the map are not geographically representative of the
          source of consumption or generation of electricity. The analysis
          conducted is based on GB as a whole.
        </li>
        <li className="p-2" id="net-zero">
          We have proposed a solution in the game (&apos;Help me find a mix that works&apos;). This
          solution is based on the CCC Net-Zero report, in which the modelled scenarios lead
          to around 34% of generation being met by nuclear power. The remaining demand is met
          by renewables (around 62%) or gas (&lt;2.5%) or storage.
          <i style={{ fontSize: '12px', display: 'inline-table' }}>
            Committee on Climate Change (2019). Net Zero – Technical Report [online]<br />
            Available at: <a className="font-bold" title="Go to https://www.theccc.org.uk/wp-content/uploads/2019/05/Net-Zero-The-UKs-contribution-to-stopping-global-warming.pdf (Opens in a new window)" href="https://www.theccc.org.uk/wp-content/uploads/2019/05/Net-Zero-The-UKs-contribution-to-stopping-global-warming.pdf" rel="noopener noreferrer" target="_blank">https://www.theccc.org.uk/wp-content/uploads/2019/05/Net-Zero-The-UKs-contribution-to-stopping-global-warming.pdf</a><br />
            <span className="text-gray-700">[Accessed: May 2020]</span><br />
          </i>
        </li>
        <li>
          The carbon intensity (the amount of carbon dioxide released during operation) of gas
          capacity: 490g/kWhCO2e
          <i style={{ fontSize: '12px', display: 'inline-table' }}>
            IPCC, AR5, 2014- mitigation of climate change – Annex III – Technology specific cost and carbon parameters – table A.II.2 [online]<br />
            Available at: <a className="font-bold" title="Go to https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7 (Opens in a new window)" href="https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7 " rel="noopener noreferrer" target="_blank">https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7</a><br />
            <span className="text-gray-700">[Accessed: May 2020]</span><br />
          </i>
        </li>
        <li className="p-2" id="cost">
          The costs for a mix shown in the game are derived from a constant value for a cost per
          week, calculated from the following:
          <table className="table-auto border-collapse border-2 w-full my-4">
            <thead />
            <tbody>
              <tr className="bg-gray-100">
                <td className="border border px-4 py-2 font-bold">Energy costs</td>
                <td className="border border px-4 py-2 font-bold">Energy used</td>
              </tr>
              <tr>
                <td className="border border px-4 py-2">£69,503</td>
                <td className="border border px-4 py-2">Cost to provide 1GWh of gas energy</td>
              </tr>
              <tr className="bg-gray-100">
                <td className="border border px-4 py-2 font-bold">Capacity costs per week</td>
                <td className="border border px-4 py-2 font-bold">Energy type</td>
              </tr>
              <tr>
                <td className="border border px-4 py-2">£3,032,308</td>
                <td className="border border px-4 py-2">Cost for 1GW of wind capacity</td>
              </tr>
              <tr>
                <td className="border border px-4 py-2">£842,308</td>
                <td className="border border px-4 py-2">Cost for 1GW of solar capacity</td>
              </tr>
              <tr>
                <td className="border border px-4 py-2">£9,096,923</td>
                <td className="border border px-4 py-2">Cost for 1GW of nuclear capacity</td>
              </tr>
              <tr>
                <td className="border border px-4 py-2">£1,023,425</td>
                <td className="border border px-4 py-2">Cost for 1GW of gas capacity</td>
              </tr>
              <tr>
                <td className="border border px-4 py-2">£167,692</td>
                <td className="border border px-4 py-2">Cost for 1GWh of battery capacity</td>
              </tr>
            </tbody>
          </table>
          These values, are derived from the assumptions on costs (£/MWh) combined with their average
          annual load factor assumptions (shown below) to produce a &apos;cost per week&apos; which is relative
          to the amount of built capacity (for nuclear, wind, solar and storage) or actual energy used (gas).
          
          Average annual load factor assumptions for cost estimates:
          <table className="table-auto border-collapse border-2 w-full my-4">
            <thead>
              <tr className="bg-gray-100">
                <td className="border border px-4 py-2 font-bold">Technology</td>
                <td className="border border px-4 py-2 font-bold">%</td>
              </tr>
              <tr>
                <td className="border border px-4 py-2">Nuclear</td>
                <td className="border border px-4 py-2">90</td>
              </tr>
              <tr>
                <td className="border border px-4 py-2">Wind</td>
                <td className="border border px-4 py-2">45</td>
              </tr>
              <tr>
                <td className="border border px-4 py-2">Solar</td>
                <td className="border border px-4 py-2">10</td>
              </tr>
            </thead>
          </table>          
        </li>
        <li className="p-2">
          We have determined the cost parameters for total cost of a generation
          mix to be:<br />
          High cost - &gt;£704,000,000 per week<br />
          Medium Cost - &gt;= £564,000,000 and &lt;= 704,000,000 per week<br />
          Low cost - &lt;£564,000,000 per week<br />
          To achieve a low cost, the successful mix must be &gt;33% above the lowest cost
          solution. For a mix to have a high cost, it is &gt;66% above the lowest cost solution.
          We have regarded mixes that have a cost greater than 100% above the lowest cost to be
          unrealistic, despite it being possible to select these mixes in the game.
        </li>
      </ol>
      <MenuBar visible={menuVisible} setVisible={setMenuVisible} wind={parsed.wind} solar={parsed.solar} nuclear={parsed.nuclear} gas={parsed.gas} storage={parsed.storage} />
    </div>
  );
};

export default Disclaimer;
