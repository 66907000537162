import React from 'react';
import PropTypes from 'prop-types';
import SummarySource from './SummarySource';
import WindTurbine from '../energySources/WindTurbine';
import SolarPanel from '../energySources/SolarPanel';
import NuclearAtom from '../energySources/NuclearAtom';
import Gas from '../energySources/Gas';
import Battery from '../storage/Battery';

const SummarySources = ({ wind, solar, nuclear, gas, storage }) => {
  return (
    <div className="w-full flex flex-col md:flex-row md:justify-between">
      <SummarySource sourceName='Wind' gigawatts={wind} units="GW">
        <WindTurbine />
      </SummarySource>
      <SummarySource sourceName='Solar' gigawatts={solar} units="GW">
        <SolarPanel />
      </SummarySource>
      <SummarySource sourceName='Nuclear' gigawatts={nuclear} units="GW">
        <NuclearAtom />
      </SummarySource>
      <SummarySource sourceName='Gas' gigawatts={gas} units="GW">
        <Gas />
      </SummarySource>
      <SummarySource sourceName='Storage' gigawatts={storage} units="GWh">
        <Battery gigawattHours={storage} />
      </SummarySource>
    </div>
  );
};

SummarySources.propTypes = {
  wind: PropTypes.number,
  solar: PropTypes.number,
  nuclear: PropTypes.number,
  gas: PropTypes.number,
  storage: PropTypes.number
};

export default SummarySources;
