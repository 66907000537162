import React from 'react';
import PropTypes from 'prop-types';
import { Plus, Minus, Info } from 'react-feather';
import { HashLink as Link } from 'react-router-hash-link';
import ReactTooltip from 'react-tooltip';

import Battery from './Battery';

const EnergyStorage = ({ gigawattHours, setGigawattHours }) => {
  const updateGigawattHours = (value) => {
    if (value > 7000) {
      setGigawattHours(7000);
    } else if (value < 0) {
      setGigawattHours(0);
    } else {
      setGigawattHours(value);
    }
  };

  return (
    <div className="flex flex-row bg-white shadow-md md:shadow-lg rounded-sm py-2 md:py-6 mb-2 md:mb-4 storage">
      <div className="w-12 h-12 md:w-20 md:h-20 ml-2 mr-2">
        <Battery gigawattHours={gigawattHours} purple={true} />
      </div>
      <div className="flex flex-row flex-grow items-center justify-between mr-4 md:items-start md:flex-col md:justify-center" data-test-id="storage">
        <Link smooth className="hidden md:block -mt-4 -mr-2 m-auto" to="/disclaimer#storage">
          <div data-tip data-for="storage">
            <Info aria-label="Information on storage" />
          </div>
        </Link>
        <span className="font-semibold leading-none md:mt-4">Storage</span>
        <span className="font-black text-2xl leading-none" data-test-id="mix-value">
          {gigawattHours}
          <span className="font-medium text-xs">
            GWh
          </span>
        </span><br />
      </div>
      <Info className="block md:hidden -mt-2" size="20" aria-label="Information on storage" data-tip data-for="storage" />
      <div className="flex flex-row items-center md:flex-col md:justify-between md:-mr-4">
        <div className={`bg-midnight-blue rounded-sm p-1 mr-2 md:mr-0 btn-minus ${ gigawattHours <= 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={() => updateGigawattHours(gigawattHours - 500)}>
          <Minus color="#65fef0" />
        </div>
        <div className={`bg-midnight-blue rounded-sm p-1 mr-2 md:mr-0 md:order-first btn-plus ${ gigawattHours >= 7000 ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={() => updateGigawattHours(gigawattHours + 500)}>
          <Plus color="#65fef0" />
        </div>
      </div>
      <ReactTooltip class="w-64 opaque" id="storage" type="light" effect="solid" place="right" border={true}
        borderColor="black" multiline={true}>
        Some free-to-use storage is available to each player at the start of the game to represent existing flexibility.
        Click to understand why!
      </ReactTooltip>
    </div>
  );
};

EnergyStorage.propTypes = {
  gigawattHours: PropTypes.number,
  setGigawattHours: PropTypes.func
};

export default EnergyStorage;
