import { getWind as getWeekOneWind } from '../data/weekOne/wind';
import { getSolar as getWeekOneSolar } from '../data/weekOne/solar';
import { getNuclear as getWeekOneNuclear } from '../data/weekOne/nuclear';
import { getStorage as getWeekOneStorage } from '../data/weekOne/storage';
import { getGas as getWeekOneGas } from '../data/weekOne/gas';
import { getWind as getWeekTwoWind } from '../data/weekTwo/wind';
import { getSolar as getWeekTwoSolar } from '../data/weekTwo/solar';
import { getNuclear as getWeekTwoNuclear } from '../data/weekTwo/nuclear';
import { getStorage as getWeekTwoStorage } from '../data/weekTwo/storage';
import { getGas as getWeekTwoGas } from '../data/weekTwo/gas';
import DEMAND from '../data/demand';

const capacityCost = {
  wind: 3032308,
  solar: 842308,
  nuclear: 9096923,
  gas: 1023425,
  storage: 167692
};

const energyCost = {
  wind: 0,
  solar: 0,
  nuclear: 0,
  gas: 69503,
  storage: 0
};

// Lower limit for a 'high cost' mix.
export const highCost = 704000000;

// Lower limit for a 'medium cost' mix.
export const mediumCost = 564000000;

// Game period. How long is the output sample period (hours). Period of game data.
export const gamePeriod = 2;

// Minimum allowed battery level at any point during week one.
export const weekOneMinimumBatteryLevel = 0.5;

// Minimum allowed battery level at any point during week one.
export const weekTwoMinimumBatteryLevel = 0;

// Interconnector capacity provided
export const interconnectorCapacityProvided = 100;

// Carbon produced by 1GWh of gas use
export const carbonFactor = 490;

// Allowed carbon emissions for net-zero
export const allowedCarbonEmissions = 79000;

// Average demand in GW for energy.
export const peakDemand = Math.max(...DEMAND.data);

// Average demand in GW for energy.
export const averageDemand = (DEMAND.data.reduce((a, b) => a + b, 0) / DEMAND.data.length) || 0;

// Cost that results in 100% cost score
export const hundredScoreCost = 470000000;

// Cost that results in 0% cost score
export const zeroScoreCost = 700000000;

// Carbon emissions that results in 100% carbon score
export const hundredScoreCarbon = 0;

// Carbon emissions that results in 0% carbon score
export const zeroScoreCarbon = 70000;

// UK demand over modelled week in MW
export const ukEnergyDemand = 6440000;

export const sumValues = function (obj) {
  return Object.values(obj).reduce((a, b) => a + b);
};

export const sumIfPositive = function (obj) {
  const values = obj.filter(value => value > 0);

  return Object.values(values.length !== 0 ? values : [0]).reduce((a, b) => a + b);
};

export const sumWeekOneEnergy = (wind, solar, nuclear, storage, gas) => {
  const weekOneArray = [
    getWeekOneWind(wind).data,
    getWeekOneSolar(solar).data,
    getWeekOneNuclear(nuclear).data,
    getWeekOneStorage(storage, wind, solar, nuclear).data,
    getWeekOneGas(gas, wind, solar, nuclear, storage).data
  ];
  return weekOneArray.reduce((r, a) => a.map((b, i) => (r[i] || 0) + b), []);
};

export const sumWeekTwoEnergy = (wind, solar, nuclear, storage, gas) => {
  const weekTwoArray = [
    getWeekTwoWind(wind).data,
    getWeekTwoSolar(solar).data,
    getWeekTwoNuclear(nuclear).data,
    getWeekTwoStorage(storage, wind, solar, nuclear).data,
    getWeekTwoGas(gas, wind, solar, nuclear, storage).data
  ];
  return weekTwoArray.reduce((r, a) => a.map((b, i) => (r[i] || 0) + b), []);
};

export const percentageMissing = (totalEnergy) => {
  const missingEnergy = totalEnergy.map((e, i) => Math.max(DEMAND.data[i] - e, 0));
  const averageMissingEnergy = (missingEnergy.reduce((a, b) => a + b, 0) / missingEnergy.length) || 0;
  return Math.round(averageMissingEnergy/averageDemand * 100) >= 0 ? Math.round(averageMissingEnergy/averageDemand * 100) : 0;
};

export const percentageExcess = (totalEnergy) => {
  const excessEnergy = totalEnergy.map((e, i) => Math.max(e - DEMAND.data[i], 0));
  const averageExcessEnergy = (excessEnergy.reduce((a, b) => a + b, 0) / excessEnergy.length) || 0;
  return Math.round(averageExcessEnergy/averageDemand * 100) >= 0 ? Math.round(averageExcessEnergy/averageDemand * 100) : 0;
};

export const getWeekOneCarbon = (gas, wind, solar, nuclear, storage) => {
  return sumValues(getWeekOneGas(gas, wind, solar, nuclear, storage).data) * gamePeriod * carbonFactor;
};

export const getWeekTwoCarbon = (gas, wind, solar, nuclear, storage) => {
  return sumValues(getWeekTwoGas(gas, wind, solar, nuclear, storage).data) * gamePeriod * carbonFactor;
};

export const getWeekOneCost = (wind, solar, nuclear, gas, storage) => {
  return {
    wind: (wind * capacityCost.wind + sumValues(getWeekOneWind(wind).data) * energyCost.wind),
    solar: (solar * capacityCost.solar + sumValues(getWeekOneSolar(solar).data) * energyCost.solar),
    nuclear: (nuclear * capacityCost.nuclear + sumValues(getWeekOneNuclear(nuclear).data) * energyCost.nuclear),
    gas: (gas * capacityCost.gas + sumValues(getWeekOneGas(gas, wind, solar, nuclear, storage).data) * energyCost.gas),
    storage: (storage * capacityCost.storage + sumValues(getWeekOneStorage(storage, wind, solar, nuclear).data) * energyCost.storage)
  };
};

export const getWeekTwoCost = (wind, solar, nuclear, gas, storage) => {
  return {
    wind: (wind * capacityCost.wind + sumValues(getWeekTwoWind(wind).data) * energyCost.wind),
    solar: (solar * capacityCost.solar + sumValues(getWeekTwoSolar(solar).data) * energyCost.solar),
    nuclear: (nuclear * capacityCost.nuclear + sumValues(getWeekTwoNuclear(nuclear).data) * energyCost.nuclear),
    gas: (gas * capacityCost.gas + sumValues(getWeekTwoGas(gas, wind, solar, nuclear, storage).data) * energyCost.gas),
    storage: (storage * capacityCost.storage + sumValues(getWeekTwoStorage(storage, wind, solar, nuclear).data) * energyCost.storage)
  };
};

export const getCostBand = (cost) => {
  return cost > highCost ? 'HIGH' : cost > mediumCost ? 'MEDIUM' : 'LOW';
};

export const getScore = (gas, nuclear, wind, solar, storage) => {
  const costScore = (1 - Math.max(Math.min((sumValues(getWeekTwoCost(wind, solar, nuclear, gas, storage)) - hundredScoreCost) / (zeroScoreCost - hundredScoreCost), 1), 0)) * 100;
  const carbonScore = (1 - Math.max(Math.min((getWeekTwoCarbon(gas, wind, solar, nuclear, storage) - hundredScoreCarbon) / (zeroScoreCarbon - hundredScoreCarbon), 1), 0)) * 100;

  return Math.round((costScore + carbonScore) / 2);
};

export const getPoundsPerMegawattHour = (wind, solar, nuclear, gas, storage) => {
  const totalMwh = ukEnergyDemand;
  const costs = getWeekTwoCost(wind, solar, nuclear, gas, storage);
  const cost = costs.wind + costs.solar + costs.nuclear + costs.gas + costs.storage;

  return Math.round(cost / (totalMwh));
};
