import React from 'react';
import PropTypes from 'prop-types';
import TypicalWeekLogo from './TypicalWeekLogo';
import CloudyWeekLogo from './CloudyWeekLogo';

const Weather = ({ week }) => {
  return (
    <div className="flex flex-row">
      { week === 'one' && (
        <>
          <div className="mr-4">
            <TypicalWeekLogo />
          </div>
          <div className="text-left text-sm">
            <p className="text-sm"><span className="font-bold">Week One</span><br />
            Average sun and wind</p>
          </div>
        </>
      )}
      { week === 'two' && (
        <>
          <div className="mr-4">
            <CloudyWeekLogo />
          </div>
          <div className="text-left text-sm">
            <p className="text-sm"><span className="font-bold">Week Two</span><br />
              Low sun and wind</p>
          </div>
        </>
      )}
    </div>
  );
};

Weather.propTypes = {
  week: PropTypes.oneOf(['one', 'two'])
};

export default Weather;
