import DEMAND from '../demand.json';
import LABELS from './labels.json';

export const getSolar = (capacity) => (
  {
    'label': 'Solar Energy Produced',
    'fill': true,
    'lineTension': 0.1,
    'backgroundColor': 'rgba(255, 120, 79, 1)',
    'borderColor': 'rgba(255, 120, 79, 1)',
    'borderWidth': 0,
    'borderCapStyle': 'butt',
    'borderDash': [],
    'borderDashOffset': 0.0,
    'borderJoinStyle': 'miter',
    'pointBorderColor': 'rgba(255, 120, 79, 1)',
    'pointBackgroundColor': 'rgba(255, 120, 79, 1)',
    'pointBorderWidth': 1,
    'pointHoverRadius': 0,
    'pointHoverBackgroundColor': 'rgba(255, 120, 79, 1)',
    'pointHoverBorderColor': 'rgba(255, 120, 79, 1)',
    'pointHoverBorderWidth': 1,
    'pointRadius': 0,
    'pointHitRadius': 1,
    'data': [0.00, 0.00, 0.00, 0.00, 0.05, 0.14, 0.15, 0.09, 0.01, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.10, 0.21, 0.16, 0.05, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.11, 0.29, 0.30, 0.15, 0.02, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.05, 0.10, 0.09, 0.05, 0.01, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.02, 0.06, 0.07, 0.04, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.02, 0.08, 0.11, 0.08, 0.01, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.01, 0.04, 0.05, 0.03, 0.00, 0.00, 0.00, 0.00].map(x => x * capacity)
  }
);

const solar = (capacity) => ({
  'labels': LABELS,
  'datasets': [
    DEMAND,
    getSolar(capacity)
  ]
});

export default solar;
