import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Menu } from 'react-feather';

import EnergySourceControls from '../keepTheLightsOn/EnergySourceControls';
import Week from '../keepTheLightsOn/Week';
import WeekTwoPlaceholder from '../keepTheLightsOn/WeekTwoPlaceholder';
import ResultsAndHints from '../keepTheLightsOn/ResultsAndHints';
import Modal from '../modal/Modal';
import WeekOneModal from '../modalHints/WeekOneModal';
import WindCapacityModal from '../modalHints/WindCapacityModal';
import SolarCapacityModal from '../modalHints/SolarCapacityModal';
import NuclearCapacityModal from '../modalHints/NuclearCapacityModal';
import GasCapacityModal from '../modalHints/GasCapacityModal';
import SummaryModal from '../modal/SummaryModal';
import Summary from '../summary/Summary';
import ModalHint from '../modalHints/ModalHint';
import MixHintButton from '../keepTheLightsOn/MixHintButton';
import ResetButton from '../keepTheLightsOn/ResetButton';
import ReevaluateButton from '../keepTheLightsOn/ReevaluateButton';
import MenuBar from '../menu/MenuBar';
import DesktopStatusBar from '../desktopStatusBar/DesktopStatusBar';
import MobileStatusBar from '../mobileStatusBar/MobileStatusBar';

import {
  allowedCarbonEmissions,
  getWeekOneCarbon,
  getWeekOneCost,
  getWeekTwoCarbon,
  getWeekTwoCost,
  percentageExcess,
  percentageMissing,
  sumWeekOneEnergy,
  sumWeekTwoEnergy
} from '../../utils/utils';

import EMPTY from '../../data/empty.json';

import './KeepTheLightsOn.css';

const KeepTheLightsOn = () => {
  const parsed = queryString.parse(window.location.search);

  const [gas, setGas] = useState(parsed.gas ? parseInt(parsed.gas) : 0);
  const [nuclear, setNuclear] = useState(parsed.nuclear ? parseInt(parsed.nuclear) : 0);
  const [wind, setWind] = useState(parsed.wind ? parseInt(parsed.wind) : 0);
  const [solar, setSolar] = useState(parsed.solar ? parseInt(parsed.solar) : 0);
  const [storage, setStorage] = useState(parsed.storage ? parseInt(parsed.storage) : 0);
  const [averageMixData, setAverageMixData] = useState(EMPTY);
  const [lowMixData, setLowMixData] = useState(EMPTY);

  const [hintModalVisible, setHintModalVisible] = useState(false);
  const [windCapacityModalVisible, setWindCapacityModalVisible] = useState(false);
  const [solarCapacityModalVisible, setSolarCapacityModalVisible] = useState(false);
  const [nuclearCapacityModalVisible, setNuclearCapacityModalVisible] = useState(false);
  const [gasCapacityModalVisible, setGasCapacityModalVisible] = useState(false);
  const [weekOneModalVisible, setWeekOneModalVisible] = useState(false);
  const [summaryVisible, setSummaryVisible] = useState(false);

  const [windCapacityModalShown, setWindCapacityModalShown] = useState(false);
  const [solarCapacityModalShown, setSolarCapacityModalShown] = useState(false);
  const [nuclearCapacityModalShown, setNuclearCapacityModalShown] = useState(false);
  const [gasCapacityModalShown, setGasCapacityModalShown] = useState(false);
  const [weekOneModalShown, setWeekOneModalShown] = useState(false);
  const [evaluated, setEvaluated] = useState(false);

  const [menuVisible, setMenuVisible] = useState(false);

  // Update mix effect
  useEffect(() => {
    const weekOneTotalEnergy = sumWeekOneEnergy(wind, solar, nuclear, storage, gas);
    const weekTwoTotalEnergy = sumWeekTwoEnergy(wind, solar, nuclear, storage, gas);

    setAverageMixData(averageMixData => ({
      ...averageMixData,
      mix: { ...(averageMixData.mix), gas, nuclear, wind, solar, storage },
      energy: { missing: percentageMissing(weekOneTotalEnergy), excess: percentageExcess(weekOneTotalEnergy) },
      carbon: getWeekOneCarbon(gas, wind, solar, nuclear, storage),
      costs: getWeekOneCost(wind, solar, nuclear, gas, storage)
    }));
    setLowMixData(lowMixData => ({
      ...lowMixData,
      mix: { ...(lowMixData.mix), gas, nuclear, wind, solar, storage },
      energy: { missing: percentageMissing(weekTwoTotalEnergy), excess: percentageExcess(weekTwoTotalEnergy) },
      carbon: getWeekTwoCarbon(gas, wind, solar, nuclear, storage),
      costs: getWeekTwoCost(wind, solar, nuclear, gas, storage)
    }));

    if (!windCapacityModalShown && wind === 180) {
      setWindCapacityModalVisible(true);
      setWindCapacityModalShown(true);
    }
    if (!solarCapacityModalShown && solar === 400) {
      setSolarCapacityModalVisible(true);
      setSolarCapacityModalShown(true);
    }
    if (!nuclearCapacityModalShown && nuclear === 36) {
      setNuclearCapacityModalVisible(true);
      setNuclearCapacityModalShown(true);
    }
    if (!gasCapacityModalShown && gas === 60) {
      setGasCapacityModalVisible(true);
      setGasCapacityModalShown(true);
    }
  }, [gas, nuclear, wind, solar, storage,
    gasCapacityModalShown, nuclearCapacityModalShown, solarCapacityModalShown, windCapacityModalShown]);

  // Week One winning mix effect
  useEffect(() => {
    if (!weekOneModalShown
      && averageMixData.energy.missing === 0
      && averageMixData.carbon <= allowedCarbonEmissions)
    {
      // Suppress capacity modals when winning
      setWindCapacityModalVisible(false);
      setSolarCapacityModalVisible(false);
      setNuclearCapacityModalVisible(false);
      setGasCapacityModalVisible(false);
      // Show week one winning modal
      setWeekOneModalVisible(true);
      setWeekOneModalShown(true);
    }
  }, [averageMixData, weekOneModalShown]);

  // Week Two winning mix effect
  useEffect(() => {
    if (averageMixData.energy.missing === 0
      && averageMixData.carbon <= allowedCarbonEmissions
      && lowMixData.energy.missing === 0
      && lowMixData.carbon <= allowedCarbonEmissions
      && !evaluated)
    {
      // Suppress capacity modals when winning
      setWindCapacityModalVisible(false);
      setSolarCapacityModalVisible(false);
      setNuclearCapacityModalVisible(false);
      setGasCapacityModalVisible(false);
      // Show summary
      setSummaryVisible(true);
      setEvaluated(true);
      // If "show me a winning mix" button is pressed, we should suppress week one modal
      setWeekOneModalVisible(false);
      setWeekOneModalShown(true);
    }
  }, [averageMixData, lowMixData, evaluated]);

  return (
    <div className="mixer-background">
      <main className="flex flex-col md:flex-row md:mb-32 select-none" data-test-id="keep-the-lights-on-container">
        <div className="flex flex-col md:flex-row py-2 order-2 md:order-1">
          <div className="w-full md:w-64 md:min-w-64 flex flex-col px-4 control-panel">
            <EnergySourceControls
              wind={wind} setWind={setWind}
              solar={solar} setSolar={setSolar}
              nuclear={nuclear} setNuclear={setNuclear}
              gas={gas} setGas={setGas}
              storage={storage} setStorage={setStorage}
            />
          </div>
          <div className="px-4 w-full md:w-64 xl:w-80">
            <Week week="one" mixData={averageMixData} />
          </div>
          <div className="px-4 w-full md:w-64 xl:w-80">
            {averageMixData.energy.missing === 0 && averageMixData.carbon <= allowedCarbonEmissions ? (
              <Week week="two" mixData={lowMixData} />
            ) : (
              <WeekTwoPlaceholder />
            )}
          </div>
          <div className="md:hidden flex justify-center px-2 mb-16" data-test-id="mix-buttons-mobile">
            <MixHintButton setWind={setWind} setSolar={setSolar} setNuclear={setNuclear} setGas={setGas} setStorage={setStorage} />
            <ReevaluateButton averageMixData={averageMixData} lowMixData={lowMixData} setSummaryVisible={setSummaryVisible} />
            <ResetButton setWind={setWind} setSolar={setSolar} setNuclear={setNuclear} setGas={setGas} setStorage={setStorage} />
          </div>
        </div>
        <div className="w-full md:flex-grow order-1 md:order-2 mobile-menu">
          <div className="md:hidden flex flex-row m-4" data-test-id="menu-btn" onClick={() => setMenuVisible(true)}>
            <Menu />
            <p className="uppercase font-bold ml-4">Menu</p>
          </div>
          <ResultsAndHints averageMixData={averageMixData} lowMixData={lowMixData} setHintModalVisible={setHintModalVisible} />
        </div>
        <DesktopStatusBar wind={wind} setWind={setWind} solar={solar} setSolar={setSolar} nuclear={nuclear}
          setNuclear={setNuclear} storage={storage} setStorage={setStorage} gas={gas} setGas={setGas}
          averageMixData={averageMixData} lowMixData={lowMixData} setSummaryVisible={setSummaryVisible} />
        <MobileStatusBar averageMixData={averageMixData} lowMixData={lowMixData} />
      </main>
      <Modal display={hintModalVisible} setDisplay={setHintModalVisible} dataTestId="hint-modal">
        <ModalHint averageMixData={averageMixData} lowMixData={lowMixData} />
      </Modal>
      <Modal display={weekOneModalVisible} setDisplay={setWeekOneModalVisible} neonGreen={true} dataTestId="week-one-modal">
        <WeekOneModal setVisible={setWeekOneModalVisible} />
      </Modal>
      <Modal display={windCapacityModalVisible} setDisplay={setWindCapacityModalVisible}>
        <WindCapacityModal />
      </Modal>
      <Modal display={solarCapacityModalVisible} setDisplay={setSolarCapacityModalVisible}>
        <SolarCapacityModal />
      </Modal>
      <Modal display={nuclearCapacityModalVisible} setDisplay={setNuclearCapacityModalVisible}>
        <NuclearCapacityModal />
      </Modal>
      <Modal display={gasCapacityModalVisible} setDisplay={setGasCapacityModalVisible}>
        <GasCapacityModal />
      </Modal>
      <SummaryModal display={summaryVisible} setDisplay={setSummaryVisible} dataTestId="summary-modal">
        <Summary gas={gas} nuclear={nuclear} wind={wind} solar={solar} storage={storage}
          averageValues={averageMixData} lowValues={lowMixData} />
      </SummaryModal>
      <div className="nav" role="navigation">
        <MenuBar visible={menuVisible} setVisible={setMenuVisible} wind={wind} solar={solar} nuclear={nuclear} gas={gas} storage={storage} />
      </div>
    </div>
  );
};

export default KeepTheLightsOn;
