import DEMAND from '../demand.json';
import LABELS from './labels.json';
import { sumWeekOneEnergy } from '../../utils/utils';

export const getSumEnergy = (mix) => (
  {
    'label': 'Total Energy Produced',
    'fill': false,
    'lineTension': 0.1,
    'backgroundColor': '#17242a',
    'borderColor': '#17242a',
    'borderWidth': 0,
    'borderCapStyle': 'butt',
    'borderDash': [],
    'borderDashOffset': 0.0,
    'borderJoinStyle': 'miter',
    'pointBorderColor': '#17242a',
    'pointBackgroundColor': '#17242a',
    'pointBorderWidth': 1,
    'pointHoverRadius': 0,
    'pointHoverBackgroundColor': '#17242a',
    'pointHoverBorderColor': '#17242a',
    'pointHoverBorderWidth': 1,
    'pointRadius': 0,
    'pointHitRadius': 1,
    'data': sumWeekOneEnergy(mix.wind, mix.solar, mix.nuclear, mix.storage, mix.gas)
  }
);

const sumEnergy = (mix) => (
  {
    'labels': LABELS,
    'datasets': [
      DEMAND,
      getSumEnergy(mix)
    ]
  });

export default sumEnergy;
