import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

import EnergyStatus from '../energyStatus/EnergyStatus';
import LoadFactorTable from '../loadFactorTable/LoadFactorTable';
import Battery from '../storage/Battery';

import { sumValues } from '../../utils/utils';

import lineOptions from '../../data/lineOptions';
import getSumWeekOneEnergy from '../../data/weekOne/sumEnergy';
import getSumWeekTwoEnergy from '../../data/weekTwo/sumEnergy';

const DemandVsProduction = ({ averageMixData, lowMixData }) => {
  return (
    <div className="justify-between block bg-white mx-auto md:p-8">
      <div>
        <h1 className="font-bold text-4xl pb-8">Demand vs production</h1>
        <p>
          You&apos;ve built the capacity to generate {averageMixData.mix.wind + averageMixData.mix.solar + averageMixData.mix.nuclear + averageMixData.mix.gas } GW of power. However, due to variable
          weather conditions, renewable energy sources, such as wind and solar, will not be able to produce at maximum
          capacity all the time. This results in production exceeding or falling short of demand. The charts below show
          the total production output, based on your chosen mix.
        </p>
      </div>
      <div className="md:flex justify-between block bg-white md:my-8 mx-auto">
        <div className="md:w-6/12 md:mr-4">
          <div>
            <h2 className="font-bold text-l mt-4 pb-4">Week One:</h2>
            <Line data={getSumWeekOneEnergy(averageMixData.mix)} options={lineOptions(sumValues(averageMixData.mix), 10)} />
            <div className="w-2/3 m-auto">
              <EnergyStatus energyExcess={averageMixData.energy.excess} energyMissing={averageMixData.energy.missing} />
            </div>
          </div>
          <div className="mt-8">
            <h2 className="font-bold text-xl pb-4">Reliability</h2>
            <p>
              Due to variable weather conditions, renewable energy sources, such as wind and solar, will not be able to
              produce at maximum capacity all the time.
            </p>
            <LoadFactorTable />
          </div>
        </div>
        <div className="md:w-6/12 md:ml-4">
          <div>
            <h2 className="font-bold text-l mt-4 pb-4">Week Two:</h2>
            <Line data={getSumWeekTwoEnergy(lowMixData.mix)} options={lineOptions(sumValues(lowMixData.mix), 10)} />
            <div className="w-2/3 m-auto">
              <EnergyStatus energyExcess={lowMixData.energy.excess} energyMissing={lowMixData.energy.missing} />
            </div>
          </div>
          <div className="flex flex-row mt-8 p-4 md:p-8 bg-neon-green-100">
            <div className="flex w-12 h-12 md:w-20 md:h-20 ml-2 mr-2">
              <Battery gigawattHours={3500} purple={true} />
            </div>
            <div className="flex flex-col w-2/3">
              <h3 className="flex font-bold text-l text-gray-700">TIP</h3>
              <h4 className="flex font-bold text-xl pb-4">Use Storage</h4>
              <p className="flex">
                Storage allows for some of the surplus energy to be stored and later used to balance a shortfall in
                energy production. At the moment, storage space is limited and very expensive to use.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DemandVsProduction.propTypes = {
  averageMixData: PropTypes.object,
  lowMixData: PropTypes.object
};

export default DemandVsProduction;
