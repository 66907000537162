import DEMAND from '../demand.json';
import LABELS from './labels.json';
import wind from './wind';
import solar from './solar';
import nuclear from './nuclear';
import { gamePeriod, interconnectorCapacityProvided, sumIfPositive, weekTwoMinimumBatteryLevel } from '../../utils/utils';

const storageData = (capacity, windCapacity, solarCapacity, nuclearCapacity) => {
  let surplusEnergy = [];

  const array = [wind(windCapacity).datasets[1].data,
      solar(solarCapacity).datasets[1].data,
      nuclear(nuclearCapacity).datasets[1].data],
    generatedWSN = array.reduce((r, a) => a.map((b, i) => (r[i] || 0) + b), []);

  for (let i = 0; i < generatedWSN.length; i++) {
    surplusEnergy.push((generatedWSN[i] - DEMAND.data[i]) * gamePeriod);
  }

  const remainingExcessEnergyInWeek = [];

  for (let i = 0; i < surplusEnergy.length; i++) {
    surplusEnergy.slice(i + 1).length > 0 ? remainingExcessEnergyInWeek.push(sumIfPositive(surplusEnergy.slice(i+1))) : remainingExcessEnergyInWeek.push(0);
  }

  const remainingEnergyInModel = [(capacity + interconnectorCapacityProvided) * weekTwoMinimumBatteryLevel + surplusEnergy[0] + remainingExcessEnergyInWeek[0]];
  const remainingEnergyToUseInWeek = [remainingEnergyInModel[0] - surplusEnergy[0] - (capacity + interconnectorCapacityProvided) * weekTwoMinimumBatteryLevel];

  const batteryCharge = [
    remainingExcessEnergyInWeek[0] + (capacity + interconnectorCapacityProvided) * weekTwoMinimumBatteryLevel + surplusEnergy[0] > (capacity + interconnectorCapacityProvided) * weekTwoMinimumBatteryLevel ?
      Math.min(Math.max((capacity + interconnectorCapacityProvided) * 0.5 + surplusEnergy[0], 0), (capacity + interconnectorCapacityProvided)) :
      Math.min(Math.max((capacity + interconnectorCapacityProvided) * 0.5 - remainingEnergyToUseInWeek[0], 0), (capacity + interconnectorCapacityProvided))
  ];

  for (let i = 1; i < surplusEnergy.length; i++) {
    remainingEnergyInModel.push(batteryCharge[i - 1] + remainingExcessEnergyInWeek[i] + surplusEnergy[i]);
    remainingEnergyToUseInWeek.push(remainingEnergyInModel[i] - surplusEnergy[i] - (capacity + interconnectorCapacityProvided) * weekTwoMinimumBatteryLevel);
    batteryCharge.push(
      surplusEnergy[i] + batteryCharge[i - 1] + remainingExcessEnergyInWeek[i] > (capacity + interconnectorCapacityProvided) * weekTwoMinimumBatteryLevel ?
        Math.min(Math.max(batteryCharge[i - 1] + surplusEnergy[i], 0), (capacity + interconnectorCapacityProvided)) :
        Math.min(Math.max(batteryCharge[i - 1] - remainingEnergyToUseInWeek[i], 0), (capacity + interconnectorCapacityProvided))
    );
  }

  const batteryPower = [((capacity + interconnectorCapacityProvided) * weekTwoMinimumBatteryLevel - batteryCharge[0]) > 0 ? ((capacity + interconnectorCapacityProvided) * weekTwoMinimumBatteryLevel - batteryCharge[0]) / gamePeriod : 0];

  for (let i = 1; i < batteryCharge.length; i++) {
    batteryPower.push((batteryCharge[i - 1] - batteryCharge[i]) > 0 ? (batteryCharge[i - 1] - batteryCharge[i]) / gamePeriod : 0);
  }

  return batteryPower;
};

export const getStorage = (capacity, wind, solar, nuclear) => (
  {
    'label': 'Storage Used',
    'fill': true,
    'lineTension': 0.1,
    'backgroundColor': 'rgba(148, 0, 211, 1)',
    'borderColor': 'rgba(148, 0, 211, 1)',
    'borderWidth': 0,
    'borderCapStyle': 'butt',
    'borderDash': [],
    'borderDashOffset': 0.0,
    'borderJoinStyle': 'miter',
    'pointBorderColor': 'rgba(148, 0, 211, 1)',
    'pointBackgroundColor': 'rgba(148, 0, 211, 1)',
    'pointBorderWidth': 1,
    'pointHoverRadius': 0,
    'pointHoverBackgroundColor': 'rgba(148, 0, 211, 1)',
    'pointHoverBorderColor': 'rgba(148, 0, 211, 1)',
    'pointHoverBorderWidth': 1,
    'pointRadius': 0,
    'pointHitRadius': 1,
    'data': storageData(capacity, wind, solar, nuclear)
  }
);

const storage = (capacity, wind, solar, nuclear) => ({
  'labels': LABELS,
  'datasets': [
    DEMAND,
    getStorage(capacity, wind, solar, nuclear)
  ]
});

export default storage;
