import React from 'react';
import PropTypes from 'prop-types';

import LightMap from '../lightMap/LightMap';
import HintText from './HintText';

const ResultsAndHints = ({ averageMixData, lowMixData, setHintModalVisible }) => {
  return (
    <div>
      <HintText averageMixData={averageMixData} lowMixData={lowMixData} setHintModalVisible={setHintModalVisible} />
      <div className="xl:w-10/12 xl:m-auto">
        <LightMap percentage={averageMixData.energy.missing === 0 ? 100 - lowMixData.energy.missing : 100 - averageMixData.energy.missing} />
      </div>
    </div>
  );
};

ResultsAndHints.propTypes = {
  averageMixData: PropTypes.object,
  lowMixData: PropTypes.object,
  setHintModalVisible: PropTypes.func
};

export default ResultsAndHints;
