import React from 'react';
import PropTypes from 'prop-types';
import SummaryOverview from './SummaryOverview';
import SocialShare from '../socialShare/SocialShare';
import SummarySources from './SummarySources';
import SummaryTable from './SummaryTable';

import { getScore, sumValues } from '../../utils/utils';

import './Summary.css';

const Summary = ({ gas, nuclear, wind, solar, storage, averageValues, lowValues }) => {
  return (
    <div className="summary-body md:m-auto mx-4 pt-8 mb-8 self-center outline-none" tabIndex="0" data-test-id="summary-body">
      <h1 className="font-bold text-3xl md:text-5xl mb-4 flex flex-row">Congratulations!</h1>
      <p className="mb-4">
        {lowValues.energy.missing > 0 ?
          <>
            You’re almost there! Well done! You’ve managed to keep the lights on for a whole week of typical UK weather – congratulations!<br />

            However, for a week of cloudy and still weather, {lowValues.energy.missing}% of the energy required to meet demand would be missing.<br />

            This is because the same number of wind and solar farms generate less electricity in adverse weather conditions. You might need to provide more than 100% of demand in total to keep the lights on when the weather is cloudy and still. Or you may want to consider adding some more energy storage to your mix so that you have something in reserve to smooth supply. Be careful, energy storage can be expensive and having high volumes of renewable power in the mix can increase the overall system cost.
          </>
          :
          <>
            You&apos;ve beaten the challenging conditions and kept the lights on for both weeks! This is because you had a good mix of low carbon generating technologies.
          </>
        }
      </p>
      <SummaryOverview score={getScore(gas, nuclear, wind, solar, storage)}
        weekOneSumCost={sumValues(averageValues.costs)}
        averageCarbon={(averageValues.carbon + lowValues.carbon) / 2} />
      <SocialShare score={getScore(gas, nuclear, wind, solar, storage)} />
      <h2 className="font-bold text-xl mb-2">Your energy mix</h2>
      <SummarySources wind={wind} solar={solar} nuclear={nuclear} gas={gas} storage={storage} />
      <h2 className="font-bold text-xl mb-2">Energy production</h2>
      <div>
        The electricity generated by 10GW of capacity could:
        <ul className="ml-8 list-disc">
          <li>Power&nbsp;
            <a className="font-bold"
              title="Go to https://www.energuide.be/en/questions-answers/how-do-i-read-the-energy-label-of-a-light-bulb/50/#:~:text=in%20watts%20(W)%2C%20the,(kilowatt%20hours)%20every%20hour (Opens in a new window)"
              href="https://www.energuide.be/en/questions-answers/how-do-i-read-the-energy-label-of-a-light-bulb/50/#:~:text=in%20watts%20(W)%2C%20the,(kilowatt%20hours)%20every%20hour"
              rel="noopener noreferrer" target="_blank">
                750 million light bulbs
            </a>.
          </li>
          <li>Power&nbsp;
            <a className="font-bold"
              title="Go to https://www.choose.co.uk/guide/how-to-calculate-household-appliance-energy-cost/ (Opens in a new window)"
              href="https://www.choose.co.uk/guide/how-to-calculate-household-appliance-energy-cost/"
              rel="noopener noreferrer" target="_blank">
                6.5 million washing machines
            </a>.
          </li>
          <li>Charge&nbsp;
            <a className="font-bold"
              title="Go to https://www.zap-map.com/charge-points/connectors-speeds/ (Opens in a new window)"
              href="https://www.zap-map.com/charge-points/connectors-speeds/"
              rel="noopener noreferrer" target="_blank">
                200,000 Electric Vehicles with rapid chargers
            </a>.
          </li>
        </ul>
        But remember, how much electricity is generated by a source depends on its load factor, which is affected by,
        for example, outages (for firm power sources) and the weather (for variable sources).
      </div>
      <SummaryTable averageWeekEnergy={averageValues.energy} lowWeekEnergy={lowValues.energy} />
    </div>
  );
};

Summary.propTypes = {
  gas: PropTypes.number,
  nuclear: PropTypes.number,
  wind: PropTypes.number,
  solar: PropTypes.number,
  storage: PropTypes.number,
  averageValues: PropTypes.object,
  lowValues: PropTypes.object
};

export default Summary;
