import DEMAND from '../demand.json';
import LABELS from './labels.json';

export const getNuclear = (capacity) => (
  {
    'label': 'Nuclear Energy Produced',
    'fill': true,
    'lineTension': 0.1,
    'backgroundColor': 'rgba(86, 203, 249, 1)',
    'borderColor': 'rgba(86, 203, 249, 1)',
    'borderWidth': 0,
    'borderCapStyle': 'butt',
    'borderDash': [],
    'borderDashOffset': 0.0,
    'borderJoinStyle': 'miter',
    'pointBorderColor': 'rgba(86, 203, 249, 1)',
    'pointBackgroundColor': 'rgba(86, 203, 249, 1)',
    'pointBorderWidth': 1,
    'pointHoverRadius': 0,
    'pointHoverBackgroundColor': 'rgba(86, 203, 249, 1)',
    'pointHoverBorderColor': 'rgba(86, 203, 249, 1)',
    'pointHoverBorderWidth': 1,
    'pointRadius': 0,
    'pointHitRadius': 1,
    'data': [0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88].map(x => x * capacity)
  }
);

const nuclear = (capacity) => ({
  'labels': LABELS,
  'datasets': [
    DEMAND,
    getNuclear(capacity)
  ]
});

export default nuclear;
