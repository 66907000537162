import React from 'react';

const NetZeroCarbonCloud = () => {
  return (
    <svg className="m-4" width="102" height="69" viewBox="0 0 102 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M80.9835 68.3736C75.8671 68.3736 71.1776 66.5453 67.5329 63.5063C63.8882 66.5453 59.1987 68.3736 54.0822 68.3736C48.9344 68.3736 44.2188 66.5228 40.5646 63.4502C36.3438 66.5456 31.1354 68.3737 25.5 68.3737C11.4167 68.3737 0 56.9569 0 42.8737C0 28.9511 11.1577 17.6346 25.0189 17.3781C26.8584 7.48801 35.5327 0 45.9559 0C53.5316 0 60.1834 3.95558 63.9587 9.91404C66.8188 8.24319 70.1465 7.28574 73.6978 7.28574C84.3764 7.28574 93.033 15.9424 93.033 26.6209C93.033 27.7527 92.9358 28.8617 92.7492 29.9402C98.3311 33.7183 102 40.1093 102 47.3571C102 58.9642 92.5906 68.3736 80.9835 68.3736Z" fill="white" />
      <path d="M43.2273 36.4545C42.7273 30.6818 38.5 27.4091 32.6364 27.4091C26.3636 27.4091 21.4091 31.5909 21.4091 39.3636C21.4091 47.1023 26.2273 51.3182 32.6364 51.3182C39.4545 51.3182 42.9091 46.5909 43.2273 42.6364L36.8182 42.5909C36.5 44.5795 35.0909 45.8182 32.7727 45.8182C29.6932 45.8182 27.9091 43.6364 27.9091 39.3636C27.9091 35.3182 29.6364 32.9091 32.8182 32.9091C35.25 32.9091 36.6364 34.3409 36.8182 36.4545H43.2273ZM68.6392 39.3636C68.6392 31.5909 63.6392 27.4091 57.321 27.4091C50.9574 27.4091 46.0028 31.5909 46.0028 39.3636C46.0028 47.0909 50.9574 51.3182 57.321 51.3182C63.6392 51.3182 68.6392 47.1364 68.6392 39.3636ZM62.1392 39.3636C62.1392 43.5455 60.4574 45.8182 57.321 45.8182C54.1847 45.8182 52.5028 43.5455 52.5028 39.3636C52.5028 35.1818 54.1847 32.9091 57.321 32.9091C60.4574 32.9091 62.1392 35.1818 62.1392 39.3636Z" fill="#59C063" />
      <path d="M71.8693 53H81.9176V50.1619H76.804V50.0852L78.0312 49.0625C81.0163 46.576 81.777 45.2848 81.777 43.7699C81.777 41.3281 79.7763 39.7301 76.6378 39.7301C73.5888 39.7301 71.6072 41.424 71.6136 44.179H74.9886C74.9822 43.0795 75.6534 42.4659 76.6378 42.4659C77.6158 42.4659 78.3125 43.0732 78.3125 44.0767C78.3125 45.0036 77.718 45.6044 76.7528 46.4034L71.8693 50.4432V53Z" fill="#59C063" />
    </svg>
  );
};

export default NetZeroCarbonCloud;
