import React from 'react';

export default () =>
  <>
    <div className="md:w-5/12">
      <h1 className="text-3xl font-bold" data-test-id="tour-card-title">Storing energy</h1>
      <div>
        <p className="mt-4" data-test-id="tour-card-blurb">
          Sometimes, there&apos;s not enough energy produced to meet demand and vice versa.
        </p>
        <p className="mt-4">
          You can balance this with storage, but currently this is an expensive option.
        </p>
        <p className="mt-4 uppercase font-bold" data-test-id="tour-card-explanation">
          Explanation
        </p>
        <p className="mt-4">
          Energy storage allows you to take surplus energy that would otherwise be wasted, store it (for example in a
          rechargeable battery) and deploy it when demand is higher than supply.
        </p>
        <p className="mt-4 uppercase font-bold">
          Tip
        </p>
        <p className="mt-4">
          Each player starts with 100 GWh of free to use storage. This reflects the flexibility available in the system
          today. This is approximately the amount of power required to fully charge 2.5 million Nissan Leaf Electric
          Vehicles.
        </p>
      </div>
    </div>
    <div className="md:w-5/12">
      <img className="w-1/10" data-test-id="tour-card-image" src="/assets/total-demand.png"
        alt="An infographic showing shortfall and surplus energy, along with an input storage component" />
    </div>
  </>;