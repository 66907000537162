import React from 'react';
import PropTypes from 'prop-types';
import { X } from 'react-feather';

import './SummaryModal.css';
import './Modal.css';

const SummaryModal = ({ display, setDisplay, children }) => {
  const visibility = display ? 'modal-show' : '';
  return (
    <div className={'modal summary-modal ' + visibility} role="dialog" data-test-id="summary-modal">
      <div className="md:my-8 max-w-5xl mx-auto p-8 md:p-12 overflow-y-auto outline-none" tabIndex="0">
        <div className="btn md:pl-8 md:pr-8 cursor-pointer btn-close" onClick={() => setDisplay(false)}>
          <X className="inline mr-2" />
          <span data-test-id="modal-close">Close</span>
        </div>
        {children}
      </div>
    </div>
  );
};

SummaryModal.propTypes = {
  className: PropTypes.string,
  display: PropTypes.bool,
  setDisplay: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array
  ]),
  dataTestId: PropTypes.string
};

export default SummaryModal;
