import React from 'react';

const GasCapacityModal = () => {
  return(
    <div className="justify-between block mx-auto md:p-8">
      <div>
        <h1 className="font-bold text-4xl pb-8">Gas Capacity</h1>
        <p>
                    The maximum capacity is 60 GW. This is enough energy to supply the UK’s peak demand. But using gas
                    will lead to high carbon emissions. Instead, we need an optimal mix of variable and firm low-carbon
                    power.
        </p>
      </div>
    </div>
  );
};

export default GasCapacityModal;
