import React from 'react';
import PropTypes from 'prop-types';

const setMix = (setWind, setSolar, setNuclear, setGas, setStorage) => {
  setWind(80);
  setSolar(60);
  setNuclear(22);
  setGas(6);
  setStorage(500);
};

const MixHintButton = ({ setWind, setSolar, setNuclear, setGas, setStorage }) => {
  return (
    <div className="m-2 md:underline md:text-neon-green-500 md:font-normal bg-midnight-blue
    rounded-sm no-underline text-white font-bold py-2 px-4 rounded-sm"
    data-test-id="mix-hint-button"
    onClick={() => setMix(setWind, setSolar, setNuclear, setGas, setStorage)}>
            Show me a winning mix
    </div>
  );
};

MixHintButton.propTypes = {
  setWind: PropTypes.func,
  setSolar: PropTypes.func,
  setNuclear: PropTypes.func,
  setGas: PropTypes.func,
  setStorage: PropTypes.func
};

export default MixHintButton;
