import React from 'react';
import PropTypes from 'prop-types';

import './NuclearAtom.css';

const NuclearAtom = ({ animate }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
      <g fill="none" fillRule="evenodd">
        <rect width="72" height="72" fill="#FFF" fillOpacity="0" />
        <path fill="#65FEF0" d="M28.8136016,11.4386378 C42.4036832,6.40969108 52.9988905,6.89680332 60.5992235,12.8999745 C68.1997412,18.9031457 72,27.3334023 72,38.1907443 C72.0245866,46.7984072 67.0874776,53.536923 57.1886733,58.4062917 C47.2898689,63.2756605 36.9761321,64.2738509 26.247463,61.4008629 C12.2713764,55.5946799 3.84700208,48.1838518 0.974339953,39.1683786 C-1.89832217,30.1529053 1.58115259,23.2217853 11.4127642,18.3750186 L28.8136016,11.4386378 Z" transform="matrix(1 0 0 -1 0 71)" />
        <g className={`NuclearAtom-source ${animate ? 'NuclearAtom-source--on' : 'NuclearAtom-source--off'}`}>
          <ellipse cx="36" cy="36" stroke="#17242A" strokeWidth=".701" rx="34" ry="13" />
          <ellipse cx="36" cy="36" stroke="#17242A" strokeWidth=".701" rx="34" ry="13" transform="rotate(55 36 36)" />
          <ellipse cx="36" cy="36" stroke="#17242A" strokeWidth=".701" rx="34" ry="13" transform="scale(-1 1) rotate(55 0 -33.155)" />
          <circle cx="65" cy="29" r="4" fill="#FFF" stroke="#17242A" strokeWidth=".701" />
          <circle cx="14" cy="15" r="4" fill="#FFF" stroke="#17242A" strokeWidth=".701" />
          <circle cx="34.538" cy="34.884" r="5.878" fill="#FFF" stroke="#17242A" strokeWidth="1.469" />
          <circle cx="57" cy="57" r="4" fill="#FFF" stroke="#17242A" strokeWidth=".701" />
        </g>
      </g>
    </svg>
  );
};

NuclearAtom.propTypes = {
  animate: PropTypes.bool,
};

export default NuclearAtom;
