import React from 'react';

export default () =>
  <>
    <div className="md:w-5/12">
      <h1 className="text-3xl font-bold" data-test-id="tour-card-title">How we generate energy</h1>
      <div data-test-id="tour-card-blurb">
        <p className="mt-4">
          You can select an amount of “capacity” (up to a maximum) from any or all of the four power sources in the game;
          wind, solar, nuclear or gas. You decide how much capacity you’d like to build for each source.
        </p>
        <p className="mt-4">
          Due to variables, such as maintenance or unfavourable weather conditions, sources will not be able to produce
          at their maximum all the time. Remember, it‘s not enough to just keep the lights on; your energy mix also
          needs to be low carbon so that the UK reaches its Net Zero target.
        </p>
      </div>
    </div>
    <div className="md:w-5/12">
      <img className="w-full" data-test-id="tour-card-image" src="/assets/wind-source.png"
        alt="Wind Turbine with plus/minus buttons, to alter a capacity in GigaWatts" />
      <div data-test-id="tour-card-explanation">
        <p className="uppercase font-bold">Explanation</p>
        <p>
          This sets the maximum amount of energy this source can produce (its capacity), but remember, it won&apos;t be able
          to produce this much electricity all the time.
        </p>
      </div>
    </div>
  </>;