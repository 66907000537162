import React from 'react';
import PropTypes from 'prop-types';
import { ArrowRight, Target } from 'react-feather';

const WeekOneModal = ({ setVisible }) => {
  return (
    <div className="justify-between block bg-neon-green-500 mx-auto md:p-8">
      <div>
        <h1 className="font-bold text-4xl pb-8">Congratulations!</h1>
        <p>
          You kept the lights on in a week with typical weather conditions!
          Now try to keep the lights on in more challenging conditions.
        </p>
        <div className="bg-midnight-blue md:flex justify-between pt-4 p-2 mt-8 mb-8">
          <div className="bg-neon-yellow md:w-2/3 parallelogram">
            <div className="parallelogram-content flex">
              <div className="upload-button">
                <Target size={32} />
              </div>
              <div>
                <h2 className="font-bold">Why not try week two?</h2>
                <p>
                  It will be more challenging, and creating a well thought-through mix will be of the utmost importance!
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row pt-4 text-neon-yellow md:justify-end md:pt-0" onClick={() => setVisible(false)}>
            Play
            <div className="ml-2">
              <ArrowRight size={24} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WeekOneModal.propTypes = {
  setVisible : PropTypes.func
};

export default WeekOneModal;
