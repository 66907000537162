import React from 'react';
import PropTypes from 'prop-types';

import DemandVsProduction from './DemandVsProduction';

import { peakDemand, sumValues } from '../../utils/utils';

const getModalHint = (averageMixData, lowMixData) => {
  let hint;

  if (sumValues(averageMixData.mix) >= peakDemand && averageMixData.energy.missing !== 0) {
    hint = <DemandVsProduction averageMixData={averageMixData} lowMixData={lowMixData} />;
  }

  return hint;
};

const ModalHint = ({ averageMixData, lowMixData }) => {
  return (
    <>
      {getModalHint(averageMixData, lowMixData)}
    </>
  );
};

ModalHint.propTypes = {
  averageMixData: PropTypes.object,
  lowMixData: PropTypes.object
};

export default ModalHint;
