import React from'react';
import PropTypes from 'prop-types';

import { allowedCarbonEmissions, getCostBand, sumValues } from '../../utils/utils';

const MobileStatusBar = ({ averageMixData, lowMixData }) => (
  <div className="md:hidden flex flex-row justify-around w-full fixed bottom-0 bg-midnight-blue text-white" data-test-id="mobile-status-bar">
    <div className="flex flex-col justify-center" data-test-id="mobile-lights-on-status">
      <div className="m-auto">All Lights On?</div>
      <div className={`m-auto font-bold text-2xl ${(averageMixData.energy.missing === 0 ? lowMixData.energy.missing : averageMixData.energy.missing) === 0 ? 'text-green-500' : 'text-red-500'}`}>
        {(averageMixData.energy.missing === 0 ? lowMixData.energy.missing : averageMixData.energy.missing) === 0 ? 'YES' : 'NO'}
      </div>
    </div>
    <div className="flex flex-col justify-center" data-test-id="mobile-cost-status">
      <div className="m-auto">Cost</div>
      <div className={`m-auto font-bold text-2xl ${getCostBand(sumValues(averageMixData.costs)) === 'HIGH' ? 'text-red-500' : getCostBand(sumValues(averageMixData.costs)) === 'MEDIUM' ? 'text-yellow-500' : 'text-green-500'}`}>
        {getCostBand(sumValues(averageMixData.costs))}
      </div>
    </div>
    <div className="flex flex-col justify-center" data-test-id="mobile-net-zero-status">
      <div className="m-auto">Net-zero?</div>
      <div className={`m-auto font-bold text-2xl ${(averageMixData.energy.missing === 0 && averageMixData.carbon <= allowedCarbonEmissions ? lowMixData.carbon : averageMixData.carbon) <= allowedCarbonEmissions ? 'text-green-500' : 'text-red-500'}`}>
        {(averageMixData.energy.missing === 0 && averageMixData.carbon <= allowedCarbonEmissions ? lowMixData.carbon : averageMixData.carbon) <= allowedCarbonEmissions ? 'YES' : 'NO'}
      </div>
    </div>
  </div>
);

MobileStatusBar.propTypes = {
  averageMixData: PropTypes.object,
  lowMixData: PropTypes.object
};

export default MobileStatusBar;
