import React from 'react';

const NuclearCapacityModal = () => {
  return(
    <div className="justify-between block mx-auto md:p-8">
      <div>
        <h1 className="font-bold text-4xl pb-8">Nuclear Capacity</h1>
        <p>
                    The maximum capacity is 36 GW. This is enough to supply 83% of the UK’s demand. This is close to the
                    highest share of energy coming from nuclear anywhere in the world. This limit reflects the available
                    nuclear licensed sites in the UK. We need an optimal mix of variable and firm low-carbon power.
        </p>
      </div>
    </div>
  );
};

export default NuclearCapacityModal;
