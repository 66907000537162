import React from 'react';

const NotFound = () => {
  return (
    <div className="text-center">
      <p>Not Found.</p>
      <a href="/keep-the-lights-on">
        Get back to mixing energy!
      </a>
    </div>
  );
};

export default NotFound;
