import LABELS from './labels.json';
import { getWind } from './wind';
import { getSolar } from './solar';
import { getNuclear } from './nuclear';
import { getStorage } from './storage';
import { getGas } from './gas';

const stack = (gasCapacity, windCapacity, solarCapacity, nuclearCapacity, storageCapacity) => ({
  'labels': LABELS,
  'datasets': [
    getNuclear(nuclearCapacity),
    getGas(gasCapacity, windCapacity, solarCapacity, nuclearCapacity, storageCapacity),
    getWind(windCapacity),
    getSolar(solarCapacity),
    getStorage(storageCapacity, windCapacity, solarCapacity, nuclearCapacity, storageCapacity)
  ]
});

export default stack;
