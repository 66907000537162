import React from 'react';
import PropTypes from 'prop-types';
import SummaryWeather from './SummaryWeather';

const SummaryTable = ({ averageWeekEnergy, lowWeekEnergy }) => {
  return (
    <table className="table-auto w-full my-4">
      <thead>
        <tr>
          <th className="px-4 py-2" />
          <th className="px-4 py-2">Energy Missing</th>
          <th className="px-4 py-2">Excess Energy</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-4 py-2">
            <SummaryWeather week='one' />
          </td>
          <td className="px-4 py-2">{averageWeekEnergy.missing}%</td>
          <td className="px-4 py-2">{averageWeekEnergy.excess}%</td>
        </tr>
        <tr>
          <td className="px-4 py-2">
            <SummaryWeather week='two' />
          </td>
          <td className="px-4 py-2">{lowWeekEnergy.missing}%</td>
          <td className="px-4 py-2">{lowWeekEnergy.excess}%</td>
        </tr>
      </tbody>
    </table>
  );
};

SummaryTable.propTypes = {
  averageWeekEnergy: PropTypes.object,
  lowWeekEnergy: PropTypes.object
};

export default SummaryTable;
