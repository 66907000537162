import React from 'react';

const WindCapacityModal = () => {
  return(
    <div className="justify-between block mx-auto md:p-8">
      <div>
        <h1 className="font-bold text-4xl pb-8">Wind Capacity</h1>
        <p>
                    The maximum capacity is 180 GW. This is enough to supply the UK’s demand, but this generation is
                    driven by wind speed, not demand. Wind alone is not enough to keep the lights on all the time. Too
                    much reliance on variable sources could see the costs of balancing the system increase. We need a
                    diverse mix of variable and firm low-carbon power.
        </p>
      </div>
    </div>
  );
};

export default WindCapacityModal;
