import React from 'react';

const EmissionsChart = () => {
  return (
    <div className="flex flex-col items-center md:h-screen">
      <img className="mb-4" src="/assets/lifetime_emissions_chart.png" alt="A chart showing approximate lifetime greenhouse gas emissions per technology" />
    </div>
  );
};

export default EmissionsChart;
