import React from 'react';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';
import { Info } from 'react-feather';
import ReactTooltip from 'react-tooltip';

import NonNetZeroCarbonCloud from '../netCarbon/NonNetZeroCarbonCloud';
import NetZeroCarbonCloud from '../netCarbon/NetZeroCarbonCloud';
import MixHintButton from '../keepTheLightsOn/MixHintButton';
import ReevaluateButton from '../keepTheLightsOn/ReevaluateButton';
import ResetButton from '../keepTheLightsOn/ResetButton';

import { allowedCarbonEmissions, getPoundsPerMegawattHour, getScore } from '../../utils/utils';

const DesktopStatusBar = ({ wind, setWind, solar, setSolar, nuclear, setNuclear, storage, setStorage, gas, setGas,
  averageMixData, lowMixData, setSummaryVisible }) => {
  const carbonAmount = averageMixData.energy.missing === 0 && averageMixData.carbon <= allowedCarbonEmissions
    ? lowMixData.carbon
    : averageMixData.carbon;
  const netZero = carbonAmount <= allowedCarbonEmissions;

  return (
    <div className="hidden md:flex flex-row justify-around w-full fixed bottom-0 bg-midnight-blue text-white pt-4 pb-4 h-32"
      data-test-id="status-bar-desktop">
      <div className="flex flex-row justify-center" data-test-id="net-zero">
        { netZero
          ? <NetZeroCarbonCloud />
          : <NonNetZeroCarbonCloud />
        }
        <div className="flex flex-col ml-4">
          <div className="m-auto flex flex-row font-bold">
            Net-zero?
            <Link smooth className="ml-2" to={`/disclaimer?wind=${wind}&solar=${solar}&nuclear=${nuclear}&storage=${storage}&gas=${gas}#net-zero`}
              data-tip data-for="net-zero">
              <Info aria-label="Information on net-zero" />
            </Link>
          </div>
          <div className={`m-auto font-bold text-2xl ${(averageMixData.energy.missing === 0 && averageMixData.carbon <= allowedCarbonEmissions ? lowMixData.carbon : averageMixData.carbon) <= allowedCarbonEmissions ? 'text-green-500' : 'text-red-500'}`}>
            {(averageMixData.energy.missing === 0 && averageMixData.carbon <= allowedCarbonEmissions ? lowMixData.carbon : averageMixData.carbon) <= allowedCarbonEmissions ? 'YES' : 'NO'}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center" data-test-id="system-cost">
        <div className="m-auto flex flex-row font-bold">
          Cost
          <Link smooth className="ml-2" to={`/disclaimer?wind=${wind}&solar=${solar}&nuclear=${nuclear}&storage=${storage}&gas=${gas}#cost`}
            data-tip data-for="cost">
            <Info aria-label="Information on cost" />
          </Link>
        </div>
        <div className="m-auto font-bold text-2xl flex flex-col">
          <meter min="0" optimum="0" low="88" high="110" max="187" value={getPoundsPerMegawattHour(wind, solar, nuclear, gas, storage)} />
          <span className="text-center">£{getPoundsPerMegawattHour(wind, solar, nuclear, gas, storage)}/MWh</span>
        </div>
      </div>
      <div className="flex flex-col justify-center" data-test-id="desktop-lights-on-status">
        <div className="m-auto font-bold">All Lights On?</div>
        <div className={`m-auto font-bold text-2xl ${(averageMixData.energy.missing === 0 ? lowMixData.energy.missing : averageMixData.energy.missing) === 0 ? 'text-green-500' : 'text-red-500'}`}>
          {(averageMixData.energy.missing === 0 ? lowMixData.energy.missing : averageMixData.energy.missing) === 0 ? 'YES' : 'NO'}
        </div>
      </div>
      <div className="flex flex-col justify-center" data-test-id="desktop-score-status">
        <div className="m-auto font-bold">Score</div>
        <div className="m-auto">
          {(averageMixData.energy.missing === 0
            && averageMixData.carbon <= allowedCarbonEmissions
            && lowMixData.energy.missing === 0
            && lowMixData.carbon <= allowedCarbonEmissions)
            ? <>
              <div className="font-bold text-2xl text-center">{getScore(gas, nuclear, wind, solar, storage)}%</div>
              <ReevaluateButton averageMixData={averageMixData} lowMixData={lowMixData} setSummaryVisible={setSummaryVisible} />
            </>
            : <div>Your score will be calculated once all the lights are on</div>
          }
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <ResetButton setWind={setWind} setSolar={setSolar} setNuclear={setNuclear} setGas={setGas} setStorage={setStorage} />
        <MixHintButton setWind={setWind} setSolar={setSolar} setNuclear={setNuclear} setGas={setGas} setStorage={setStorage} />
      </div>
      <ReactTooltip class="opaque" id="net-zero" type="light" effect="solid" border={true} borderColor="black">
        You can add a small amount of gas and still achieve a Net Zero mix. Click to see why!
      </ReactTooltip>
      <ReactTooltip class="opaque" id="cost" type="light" effect="solid" border={true} borderColor="black">
        The cost of your mix is dependant on how much of each technology you choose. Click to see the calculation!
      </ReactTooltip>
    </div>
  );
};

DesktopStatusBar.propTypes = {
  wind: PropTypes.number,
  setWind: PropTypes.func,
  solar: PropTypes.number,
  setSolar: PropTypes.func,
  nuclear: PropTypes.number,
  setNuclear: PropTypes.func,
  gas: PropTypes.number,
  setGas: PropTypes.func,
  storage: PropTypes.number,
  setStorage: PropTypes.func,
  averageMixData: PropTypes.object,
  lowMixData: PropTypes.object,
  setSummaryVisible: PropTypes.func
};

export default DesktopStatusBar;
