import React, { useState } from 'react';
import queryString from 'query-string';
import { Menu } from 'react-feather';

import MenuBar from '../menu/MenuBar';
import Accordion from '../accordion/Accordion';

import './Faqs.css';

const Faqs = () => {

  const [menuVisible, setMenuVisible] = useState(false);
  const parsed = queryString.parse(window.location.search);

  return (
    <div className="faqs-body md:m-auto pt-8 ml-4 mr-4" data-test-id="faqs-container">
      <div className="h-12 flex flex-row my-2" data-test-id="menu-btn" onClick={() => setMenuVisible(true)}>
        <Menu />
        <p className="uppercase font-bold ml-4">Menu</p>
      </div>
      <h1 className="font-bold text-3xl">FAQs</h1>
      <section className="shadow">
        <h2 className="font-bold text-xl mt-4 mb-4">The Current Energy Mix</h2>
        <Accordion
          title="What is the UK’s electricity generation mix?"
          content="
            <p>In 2019, 54% of UK electricity came from low-carbon sources, comprising: 16% from nuclear, 23% from wind and solar, and 15% from other renewable sources.</p>
            <ul class=&quot;list-disc ml-4&quot;>
              <li>'Renewable' or 'intermittent' energy includes wind, solar, hydro and bioenergy.</li>
              <li>'Low carbon' energy includes wind, solar, hydro, bioenergy and nuclear.</li>
              <li>'Fossil Fuels' include coal, oil and gas.</li>
              <li>'Firm' or 'Baseload' refers to dispatchable, non-weather dependant energy sources like gas and nuclear.</li>
            </ul>
            <i class=&quot;text-gray-700&quot;>Source: Energy Trends, March 2020, Table 5.1</i><br/>
            <a class=&quot;font-bold&quot; title=&quot;https://www.gov.uk/government/statistics/electricity-section-5-energy-trends (Opens in a new window)&quot; href=&quot;https://www.gov.uk/government/statistics/electricity-section-5-energy-trends&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>https://www.gov.uk/government/statistics/electricity-section-5-energy-trends</a>"
        />
        <Accordion
          title="What is the current UK energy consumption?"
          content="<p>
                     Total electricity generated in the UK in 2019 was 323.7 TWh, which remains relatively consistent
                     with the preceding years. The UK consumed around 294.3 TWh of electricity across commercial and
                     domestic consumers.<sup><a class=&quot;font-bold&quot; title=&quot;https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/877047/Press_Notice_March_2020.pdf (Opens in a new window)&quot; href=&quot;https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/877047/Press_Notice_March_2020.pdf&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[1]</a></sup>
                     1 TWh is the same as 1,000 GWh, 1,000,000 MWh and 1,000,000,000 KWh.<br/>
                     Boiling the kettle consumes around 0.1 kWh<sup><a class=&quot;font-bold&quot; title=&quot;https://www.npower.com/blog/2013/02/01/how-much-appliances-cost-to-run (Opens in a new window)&quot; href=&quot;https://www.npower.com/blog/2013/02/01/how-much-appliances-cost-to-run&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[2]</a></sup> of electricity each time and it requires around 2 kWh<sup><a class=&quot;font-bold&quot; title=&quot;https://www.forbes.com/sites/christopherhelman/2013/09/07/how-much-energy-does-your-iphone-and-other-devices-use-and-what-to-do-about-it/#67c3f5472f70 (Opens in a new window)&quot; href=&quot;https://www.forbes.com/sites/christopherhelman/2013/09/07/how-much-energy-does-your-iphone-and-other-devices-use-and-what-to-do-about-it/#67c3f5472f70&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[3]</a></sup>
                     to charge a mobile phone for a whole year.<br/>
                     <b>That means the UK consumed enough energy to boil nearly 3 trillion kettles, or enough to charge your phone for nearly 150 billion years.</b><br/>
                     To further add some context around energy consumption, the London underground consumes around
                     1.2 TWh<sup><a class=&quot;font-bold&quot; title=&quot;https://www.wired.co.uk/article/london-underground-tfl-green-energy (Opens in a new window)&quot; href=&quot;https://www.wired.co.uk/article/london-underground-tfl-green-energy&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[4]</a></sup> each year, for example. Data centres are very power hungry, the larger ones consuming
                     around 30 GWh<sup><a class=&quot;font-bold&quot; title=&quot;https://www.techuk.org/images/programmes/DataCentres/Data_Centres_and_Power.pdf (Opens in a new window)&quot; href=&quot;https://www.techuk.org/images/programmes/DataCentres/Data_Centres_and_Power.pdf&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[5]</a></sup> per year, which is roughly a quarter of the power required by all the houses in the
                     Local Authority of Hartlepool for a year<sup><a class=&quot;font-bold&quot; title=&quot;https://www.gov.uk/government/statistical-data-sets/regional-and-local-authority-electricity-consumption-statistics (Opens in a new window)&quot; href=&quot;https://www.gov.uk/government/statistical-data-sets/regional-and-local-authority-electricity-consumption-statistics&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[6]</a></sup>, which is home to around 94,000 people. Global data
                     centre energy demand around 200 TWh<sup><a class=&quot;font-bold&quot; title=&quot;https://www.iea.org/reports/data-centres-and-data-transmission-networks (Opens in a new window)&quot; href=&quot;https://www.iea.org/reports/data-centres-and-data-transmission-networks&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[7]</a></sup>, which is similar to the energy demand of Turkey.<sup><a class=&quot;font-bold&quot; title=&quot;https://en.wikipedia.org/wiki/List_of_countries_by_electricity_consumption (Opens in a new window)&quot; href=&quot;https://en.wikipedia.org/wiki/List_of_countries_by_electricity_consumption&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[8]</a></sup>
                   </p>
                   <i class=&quot;text-gray-700&quot;>Sources:</i><br/>
                   <a class=&quot;font-bold&quot; title=&quot;https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/877047/Press_Notice_March_2020.pdf (Opens in a new window)&quot; href=&quot;https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/877047/Press_Notice_March_2020.pdf&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[1]&nbsp;https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/877047/Press_Notice_March_2020.pdf</a><br/>
                   <a class=&quot;font-bold&quot; title=&quot;https://www.npower.com/blog/2013/02/01/how-much-appliances-cost-to-run (Opens in a new window)&quot; href=&quot;https://www.npower.com/blog/2013/02/01/how-much-appliances-cost-to-run&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[2] https://www.npower.com/blog/2013/02/01/how-much-appliances-cost-to-run</a><br/>
                   <a class=&quot;font-bold&quot; title=&quot;https://www.forbes.com/sites/christopherhelman/2013/09/07/how-much-energy-does-your-iphone-and-other-devices-use-and-what-to-do-about-it/#67c3f5472f70 (Opens in a new window)&quot; href=&quot;https://www.forbes.com/sites/christopherhelman/2013/09/07/how-much-energy-does-your-iphone-and-other-devices-use-and-what-to-do-about-it/#67c3f5472f70&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[3] https://www.forbes.com/sites/christopherhelman/2013/09/07/how-much-energy-does-your-iphone-and-other-devices-use-and-what-to-do-about-it/#67c3f5472f70</a><br/>
                   <a class=&quot;font-bold&quot; title=&quot;https://www.wired.co.uk/article/london-underground-tfl-green-energy (Opens in a new window)&quot; href=&quot;https://www.wired.co.uk/article/london-underground-tfl-green-energy&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[4] https://www.wired.co.uk/article/london-underground-tfl-green-energy</a><br/>
                   <a class=&quot;font-bold&quot; title=&quot;https://www.techuk.org/images/programmes/DataCentres/Data_Centres_and_Power.pdf (Opens in a new window)&quot; href=&quot;https://www.techuk.org/images/programmes/DataCentres/Data_Centres_and_Power.pdf&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[5] https://www.techuk.org/images/programmes/DataCentres/Data_Centres_and_Power.pdf</a><br/>
                   <a class=&quot;font-bold&quot; title=&quot;https://www.gov.uk/government/statistical-data-sets/regional-and-local-authority-electricity-consumption-statistics (Opens in a new window)&quot; href=&quot;https://www.gov.uk/government/statistical-data-sets/regional-and-local-authority-electricity-consumption-statistics&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[6] https://www.gov.uk/government/statistical-data-sets/regional-and-local-authority-electricity-consumption-statistics</a><br/>
                   <a class=&quot;font-bold&quot; title=&quot;https://www.iea.org/reports/data-centres-and-data-transmission-networks (Opens in a new window)&quot; href=&quot;https://www.iea.org/reports/data-centres-and-data-transmission-networks&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[7] https://www.iea.org/reports/data-centres-and-data-transmission-networks</a><br/>
                   <a class=&quot;font-bold&quot; title=&quot;https://en.wikipedia.org/wiki/List_of_countries_by_electricity_consumption (Opens in a new window)&quot; href=&quot;https://en.wikipedia.org/wiki/List_of_countries_by_electricity_consumption&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>[8] https://en.wikipedia.org/wiki/List_of_countries_by_electricity_consumption</a><br/>"
        />
        <Accordion
          title="Is the UK energy mix changing?"
          content="
            In 2003, the UK government began its journey to a low-carbon energy system, with a White Paper that set a
            target for a 60% reduction in emissions by 2050.  Since then, spurred by the landmark Stern Review in 2006,
            the target has been progressively strengthened. First, to 80% in 2008 and most recently, in July 2019, the
            UK government announced that it had set a new goal of reaching Net Zero carbon emissions by 2050.<br/>
            In order to reach this target, there has been a strong focus on decarbonisation, including an increase in
            renewable power and a decreased reliance on coal power. All remaining UK coal plants are to be shut down by
            2024.<br/>
            Over its lifetime, the UK’s nuclear fleet has made a huge contribution to decarbonising our power supply. In
            2018, EDF Energy’s eight nuclear power stations generated enough low carbon electricity to power 56% of UK
            homes (equivalent to taking almost 9.3 million cars off UK roads for a year). However, by the early 2030s,
            the majority of the UK’s nuclear power plants are set to retire. New low carbon baseload power is required
            otherwise the UK will need to rely more heavily on electricity imports from other countries where we cannot
            control the carbon intensity of the power source.<br/>
            The Energy Systems Catapult has recently published a report suggesting new nuclear capacity, be it large
            nuclear or AMR/SMR, is required for the UK to meet Net Zero.<br/>
            <i class=&quot;text-gray-700&quot;>Source: Nuclear for Net Zero, Energy Systems Catapult</i><br/>
            <a class=&quot;font-bold&quot; title=&quot;https://es.catapult.org.uk/reports/nuclear-for-net-zero/ (Opens in a new window)&quot; href=&quot;https://es.catapult.org.uk/reports/nuclear-for-net-zero/&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>https://es.catapult.org.uk/reports/nuclear-for-net-zero/</a>"
        />
        <Accordion
          title="Are there any countries that have achieved Net Zero?"
          content="
            Looking to other countries that have achieved Net Zero in their electricity system is a great way to
            understand how the UK can achieve its target.<br/>
            You can view the real time generating mix and carbon intensity of different countries on the “electricity
            map”
            <a class=&quot;font-bold&quot;
              title=&quot;http://www.electricitymap.org/ (Opens in a new window)&quot;
              href=&quot;http://www.electricitymap.org/&quot; rel=&quot;noopener noreferrer&quot;
              target=&quot;_blank&quot;>here
            </a>.<br/>
            Countries shown as green meet demand with zero carbon emissions. These countries either make use of natural
            geography (if they have it), using geothermal power or hydro to provide reliable baseload power, or (where
            they don’t) use a mixture of renewables and nuclear.<br/>
            The natural geography of the UK limits its ability to add additional hydro or geothermal power. The UK is,
            therefore, more like France and Sweden, which have decarbonised electricity systems due to a high reliance
            on nuclear and renewable power."
        />
      </section>
      <section className="shadow">
        <h2 className="font-bold text-xl mt-4 mb-4">Achieving Net Zero</h2>
        <Accordion
          title="What do I need to do to achieve Net Zero?"
          content="
            To achieve Net Zero in the game your energy system should be carbon neutral. In practice being carbon
            neutral means that any greenhouse gases from electricity generation must be eliminated or offset. The game
            does allow for a small amount of carbon emissions to reflect the potential use of gas-fired peaking plants,
            as this reflects the approach taken by the Committee on Climate Change (CCC) in their report.<br/>
            Due to very substantial progress made since 2003, only around 15% of total greenhouse emissions in the UK
            now come from the electricity sector. However, in order to decarbonise other sectors, such as industry,
            transport and heating, we need to increase electrification, for example, using electric vehicles instead of
            petrol and diesel engines.<br/>
            The CCC has said, to reach Net Zero, UK electricity demand will need to double. This means we will need four
            times the amount of low carbon generation currently available.<br/>
            <i class=&quot;text-gray-700&quot;>
              Source: Committee & Climate Change Further Ambition Scenario. Committee on Climate Change (2019) Net Zero:
              The UK’s contribution to stopping global warming.
            </i><br/>
            <a class=&quot;font-bold&quot; title=&quot;https://www.theccc.org.uk/wp-content/uploads/2019/05/Net-Zero-The-UKs-contribution-to-stopping-global-warming.pdf (Opens in a new window)&quot; href=&quot;https://www.theccc.org.uk/wp-content/uploads/2019/05/Net-Zero-The-UKs-contribution-to-stopping-global-warming.pdf&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>https://www.theccc.org.uk/wp-content/uploads/2019/05/Net-Zero-The-UKs-contribution-to-stopping-global-warming.pdf</a>"
        />
        <Accordion
          title="In addition to achieving Net Zero, what do I need to consider to create an optimal energy mix?"
          content="
            Creating an optimal energy mix is a complex balancing act. It requires a mix of different technologies, and
            can be thought of in terms of the energy trilemma: sustainability, affordability, and security of supply.
            The game is a simplification of the very complex work undertaken by different stakeholders (including Ofgem,
            National Grid and the UK Government) to keep the lights on.<br/>
            Sustainability – a sustainable electricity system is powered by a mix of renewables and reliable low-carbon
            power, such as nuclear.<br/>
            Affordability - energy must be delivered at a fair cost to the consumer. Traditionally cheap sources of
            energy, such as coal, are being phased out to meet Net Zero. Therefore, it is important to find a balance
            between the cost of technology, the economics of storing energy and the system costs.<br/>
            Security of supply - the energy system must meet demand to keep the lights on. Trying to match energy supply
            and demand is difficult. If you tried to meet today’s demand with only nuclear, you would need to install
            40GW because nuclear generates for about 90% of the time (due to outages). With offshore wind, you would
            need to install 75GW because wind generates about 50% of the time (due to weather). There would also be a
            shortfall because the shape of output does not match the shape of supply (i.e. demand peaks in the evening
            as people get home from work).<br/>
            Balancing the competing demands of sustainability, affordability and security requires a mix of different
            technology types."
        />
        <Accordion
          title="I understand that we need low carbon energy to achieve Net Zero, but what about lifecycle carbon emissions?"
          content="
            Nuclear and renewable technologies do not produce greenhouse gases when they generate electricity. However,
            some carbon emissions, known as lifecycle emissions, are produced during the construction, operation and
            decommissioning of these projects, for example, in mining and smelting steel or making concrete.<br/>
            The lifecycle carbon emissions for nuclear are comparable with that of wind technologies and lower than all
            other technologies.<br/>
            Conversely, the lifecycle emissions for gas-fired generation are high, even with carbon capture and storage
            (CCS). This is because of leakage along the natural gas supply chain.<br/>
            <i class=&quot;text-gray-700&quot;>
              Source: IPCC (2014) Climate Change 2014: Mitigation of Climate Change. p. 1335 Cambridge University Press,
              Cambridge, United Kingdom and New York, NY, USA.
            </i>"
        />
        <Accordion
          title="Why does my energy mix not achieve Net Zero?"
          content="
            Your energy mix may not meet the Net Zero target if you have selected a large amount of gas - a carbon
            intensive energy source. In 2017, carbon dioxide contributions from gas made up 43.9% of the UK’s
            territorial carbon dioxide emissions. Carbon capture and storage (CCS) technologies, once commercially
            viable at scale, could capture a large proportion of the carbon produced when gas is burnt. However, CCS
            does not capture 100% of the carbon.<br/>
            <a class=&quot;font-bold&quot; title=&quot;http://www.globalcarbonatlas.org/en/CO2-emissions (Opens in a new window)&quot; href=&quot;http://www.globalcarbonatlas.org/en/CO2-emissions&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>http://www.globalcarbonatlas.org/en/CO2-emissions</a>"
        />
        <Accordion
          title="My total power capacity is much greater than the average UK demand, why does my energy mix not meet demand?"
          content="
            The total power capacity of your mix may seem high, however, you will not meet demand unless you have
            selected a mix that meets the demand profile at all times. If your mix includes technologies that are
            intermittent, like wind and solar, they will not always generate at full capacity. Nuclear power, although
            not weather dependent, still requires maintenance outages like all energy generating technologies.<br/>
            Flexible technologies, such as energy storage, can help you meet demand because they can be made available
            at times of peak demand (especially in the winter mornings and evenings). However, these technologies can be
            expensive. It is important to consider the economics of storing energy, as opposed to overbuilding and
            generating excess power, when building your optimal energy mix.<br/>
            Overall, whilst storage is useful for smoothing supply and demand over short periods, it is very expensive
            to store lots of energy over long periods."
        />
        <Accordion
          title="Why is my energy mix so expensive?"
          content="
            Estimating the costs of an energy mix is challenging and must take into account the total system cost.<br/>
            As we move towards a decarbonised energy sector, the proportion of wind and solar on the system will
            increase. Wind and solar provide a great source of renewable energy, however, it is a variable source of
            energy (i.e. they generate more when it is sunny/windy) and, therefore, have higher balancing and system
            servicing costs. For example, a higher proportion of renewables can lead to an increased need for energy
            storage and/or a need to overbuild.<br/>
            Achieving Net Zero by 2050 and decarbonising the energy sector must be achieved at a fair cost to the
            consumer. It is, therefore, necessary to include a mix of renewables and reliable low-carbon power in your
            energy mix."
        />
        <Accordion
          title="My energy mix has a high system cost, but I have lots of excess energy. Can I sell my surplus to other
            countries and reduce my system cost?"
          content="
            The game is a simplified version of the electricity system and the model does not allow for excess energy to
            be sold to other markets. In reality, interconnection with other countries allows power to be bought from
            and sold to neighbouring grids. This can be a helpful way to balance the grid and can reduce costs for the
            consumer. The UK has three existing interconnectors to Europe and more are planned, but there are limits to
            this trading as it is often sunny or windy in neighbouring countries at the same time, meaning that
            countries want to buy or sell simultaneously."
        />
        <Accordion
          title="How do weather conditions impact my energy mix?"
          content="
            Weather conditions impact the amount of energy renewable technologies, such as wind and solar, generate.<br/>
            In the game we have taken two weeks based on real data - a still and cloudy week featuring a nuclear power
            outage, and a week of average wind and average sunshine. In the still and cloudy week renewables may not
            produce enough energy to meet demand, and this may result in a shortfall. In contrast, in a very windy and
            sunny week, renewables might produce more energy than is needed to meet demand, resulting in a surplus.
            Throughout every week there are likely to be times of both shortfall and surplus because the times of day
            that we use energy do not always correspond with when electricity is generated (i.e. we want to keep the
            lights on after sunset).<br/>
            It is key that we create an energy mix that can meet demand, regardless of the weather or time of day. As
            such, it is important to have a mix of technologies with different characteristics (i.e. renewables, nuclear
            and energy storage).<br/>"
        />
        <Accordion
          title="Nuclear is not weather dependent, why does the output vary between weeks?"
          content="
            Nuclear provides a firm low-carbon source of energy and is not weather dependent.<br/>
            In the game we have taken two weeks based on real data - a still and cloudy week featuring a nuclear power
            outage, and a week of average wind and average sunshine. Nuclear outages occur when a station has to go
            offline for scheduled maintenance and refuelling. Overall, due to outages, nuclear generates about 90% of
            the time. This is why the nuclear output drops slightly for Week Two in the game."
        />
        <Accordion
          title="How realistic is the modelling used in the game and how has it been done?"
          content="
            We have tried to make the game accurate by using as much real data as possible. However, the UK’s energy
            system is very complex, so we have simplified the model for the purposes of the game.<br/>
            In addition to the technologies in this game, interconnectors, biomass and demand side response (DSR) also
            play a key role in ensuring National Grid can balance supply and demand. These technologies have not been
            modelled explicitly, but to compensate for this, we have allowed you to meet demand with up to 5% gas and
            still meet the Net Zero target. We also recognise the growing importance of CCS, SMRs and hydrogen in our
            future electricity mix, but have not utilised these in the game to reduce complexity.<br/>
            For further information, please see our <a class=&quot;font-bold&quot; href=&quot;/disclaimer&quot;>Disclaimers.</a>"
        />
      </section>
      <section className="shadow">
        <h2 className="font-bold text-xl mt-4 mb-4">Gas</h2>
        <Accordion
          title="We use a lot of gas in our current mix and that works well for meeting demand, why can't we have a gas heavy mix?"
          content="
            The UK government has committed to achieving Net Zero by 2050. To do this, the proportion of gas in our
            energy mix will need to be reduced. Burning gas releases carbon dioxide into the atmosphere and in 2017
            these emissions contributed 43.9% of overall UK carbon emissions.<br/>
            Carbon capture and storage (CCS) is a solution that would allow carbon emissions to be captured, rather than
            entering the atmosphere.<br/>
            The Committee on Climate Change has said that CCS is “a necessity not an option” and sees its role as vital
            role in achieving Net Zero. However, at the moment, CCS has not been proved commercially viable at scale.
            Moreover, the capture rate is not 100% and the issue of lifecycle emissions will remain.<br/>
            <i class=&quot;text-gray-700&quot;>Source:</i>
            <a class=&quot;font-bold&quot; title=&quot;https://twitter.com/theCCCuk/status/1123838922570833925 (Opens in a new window)&quot; href=&quot;https://twitter.com/theCCCuk/status/1123838922570833925&quot; rel=&quot;noopener noreferrer&quot; target=&quot;_blank&quot;>https://twitter.com/theCCCuk/status/1123838922570833925</a>"
        />
      </section>
      <section className="shadow">
        <h2 className="font-bold text-xl mt-4 mb-4">Renewables (Wind and Solar)</h2>
        <Accordion
          title="Can you keep the lights on with 100% renewables?"
          content="
            It is more difficult to keep the lights on when renewables are not supported by other technologies as part
            of an energy mix.<br/>
            This is because renewable energy is a variable source of generation and its generation profile doesn’t match
            the UK’s demand profile - wind farms generate when it’s windy and solar panels generate when it’s sunny.<br/>
            Therefore, having a high proportion of renewables in your energy mix requires overbuilding to meet demand.<br/>
            In order to create an energy mix that can keep the lights on it is important to have a mix of energy sources
            that also provide low carbon and affordable energy."
        />
        <Accordion
          title="Why is it so difficult to get a low system cost with renewables, wind and sunshine are free, surely
            they should be the cheapest source of power?"
          content="
            Although wind and sunshine are free, there are costs associated with developing, installing and maintaining
            renewable technologies, as well as the costs of maintaining and balancing the National Grid.  Over the past
            few years, governments around the world have incentivised the adoption of renewable technologies. This has
            allowed for developments in technology and reduction of costs in the industry. Today, renewables compare
            favourably with other sources of energy in terms of upfront costs.<br/>
            Whilst the costs of installing and maintaining renewable technologies are competitive with other sources of
            energy, there are additional ‘system costs’ associated with renewables. One way to meet demand with
            renewable generation is to overbuild – this is when the capacity of renewable energy sources is intended to
            be greater than demand, so that even when weather conditions are not favourable renewable sources will still
            be able to meet demand. This solution is costly and requires a large amount of land or seabed.<br/>
            Renewables are variable, which means that the energy they generate is dependent on the weather and will not
            always meet demand. Additional technologies, such as battery storage, can be used to store excess energy
            generated when weather conditions are favourable and then help meet demand when generation is low, but this
            carries an additional cost.<br/>
            In order to create an energy mix at an affordable cost to the consumer it is important to have a mix of
            energy sources."
        />
      </section>
      <section className="shadow">
        <h2 className="font-bold text-xl mt-4 mb-4">Energy Storage</h2>
        <Accordion
          title="Why is energy storage so expensive?"
          content="
            There are different forms of energy storage, including batteries and pumped storage. Battery storage is an
            increasingly established technology for short-term storage but does not currently provide a solution to
            long-term storage.  The cost of battery storage is also relatively high.<br/>
            Despite the high costs associated with energy storage, it is seen as vital to achieving Net Zero by 2050. As
            the proportion of variable renewable energy increases, energy storage can help us meet demand. For example,
            a battery can store the excess energy generated by a solar panel in the middle of a Summer’s day. The
            battery can then provide this energy later in the day, at a time when demand is high and the sun has set.<br/>
            In order to create an energy mix at an affordable cost to the consumer it is important to have a mix of
            energy sources."
        />
        <Accordion
          title="Why does energy storage play a role in the energy mix?"
          content="
            The UK government has committed to bringing all carbon emissions to Net Zero by 2050. In order to achieve
            this, we need to source energy from renewable and low carbon technologies and still ensure that we can
            balance supply and demand - this is where energy storage can play a vital role.<br/>
            Energy supply does not always match demand. Batteries can be used to store excess energy produced and supply
            it later, at a time when demand is high or to balance the grid to ensure that there are no power outages.
            However, the cost of battery storage is relatively high."
        />
      </section>
      <section className="shadow">
        <h2 className="font-bold text-xl mt-4 mb-4">Nuclear</h2>
        <Accordion
          title="How long does it take to build a nuclear power station?"
          content="
            The construction period for a large nuclear power station (like that being built at Hinkley Point C) is
            around 10 years. There is also a long period before construction begins during which the project is
            developed and licensed. However, the average lifespan of a nuclear power station is 60 years, far longer
            than other generating technologies."
        />
        <Accordion
          title="How can the UK deliver new nuclear power?"
          content="
            The UK has a sophisticated regulatory regime to ensure that the planning of a new nuclear power station is
            done to the highest standard. All aspects of the project are considered, including: safety, the effect on
            surrounding communities and wildlife, waste and decommissioning, and climate change. A project only gains
            approval once every regulatory requirement has been fulfilled.<br/>
            The UK is currently building a new nuclear power station in Somerset, called Hinkley Point C (HPC). The HPC
            design has undergone all the necessary safety checks to fulfil the regulatory requirements.  This means that
            the same design could be used to build future power stations, saving time and money.<br/>
            There are numerous advantages beyond the energy system which come from new nuclear build. Nuclear offers a
            considerable boost to the regional economy as it requires materials and services from a variety of companies
            creating a supply chain. There are also great job opportunities within the nuclear industry.<br/>
            A project such as HPC creates 25,000 roles (40,000 jobs when the wider supply chain is taken into account)
            and significant ongoing job opportunities. These jobs are UK wide and cover a range of industries and skill
            sets.<br/>"
        />
        <Accordion
          title="I understand that to meet Net Zero we will need nuclear, but can’t we refuel the existing nuclear power
            stations rather than build new ones?"
          content="
            Many of the UK’s nuclear power stations were built in the 1970s and 1980s, and are reaching the end of their
            (already extended) lifetimes. The most recent nuclear power station in the UK is the pressurised water
            reactor at Sizewell B, it is hoped to extend the life of this station beyond 2050, so it can provide
            reliable low-carbon power to help meet the Net Zero target."
        />
      </section>
      <MenuBar visible={menuVisible} setVisible={setMenuVisible} wind={parsed.wind} solar={parsed.solar} nuclear={parsed.nuclear} gas={parsed.gas} storage={parsed.storage} />
    </div>
  );
};

export default Faqs;
